import { Injectable, Injector } from '@angular/core';

import { IAppSetttings } from '../models/app-settings';

/**
 * Lazy loaded service for getting application settings.
 */
@Injectable({ providedIn: 'root' })
export class AppSettingsFactory implements IAppSetttings {
	private settings: IAppSetttings;

	constructor(private _injector: Injector) {
		const item = this._injector.get('AppSettingsService'); // TODO: Depreciated, upgrade when possible.
		this.settings = item;
	}

	get broadcast(): boolean {
		return this.settings.broadcast;
	}
	get apiUrl(): string {
		return this.settings.apiUrl;
	}
	get production(): boolean {
		return this.settings.production;
	}
	get integraPayProduction(): boolean {
		return this.settings.integraPayProduction;
	}
	get version(): string {
		return this.settings.version;
	}
}
