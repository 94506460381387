<div class="card mt-2 drop-zone" appDropZone>
	
	<h2 class="drop-zone-container" appDropZoneContainer (filesDropped)="onFilesDropped($event)">
		<div class="container h-100">
			<div class="row align-items-center h-100">
				<div class="mx-auto">
					Drop files here to upload
				</div>
			</div>
		</div>
	</h2>

	<div class="card-body pb-0" *ngIf="clientQuery">
		<div class="d-flex">
			<h5 class="card-title mb-4 me-auto">Attachments</h5>

			<button *ngIf="_auth.user.staff" type="button" class="btn btn-link p-0 me-2" style="border: 0px; z-index: 1;"
				[disabled]="busy">
				<img class="btn-hand" src="./assets/images/box-logo.png" width="43" height="43"
					(click)="boxUploadClick()" />
			</button>
			
			<input #fileInput name="file" type="file" multiple hidden="true" (change)="onUploadFiles($event)" />

			<button *ngIf="trusteeView" type="button" class="btn btn-dark btn-hand" style="z-index: 1;" aria-label="Add document" 
				(click)="fileInput.click()"
				[disabled]="busy">
				<span>Add Attachments</span>
			</button>

			<button *ngIf="!trusteeView" type="button" class="btn btn-dark btn-icon btn-hand" style="z-index: 1;" aria-label="Add document" 
				(click)="fileInput.click()"
				[disabled]="busy">
				<i class="fa fa-paperclip text-white btn-hand" aria-hidden="true"></i>
			</button>
		</div>

		<div class="d-flex flex-row p-0 g-0" style="margin-top: -20px;">

			<!-- Left -->
			<div style="flex-grow: 1">
				<div style="min-height: 60px;">
					<div class="d-flex flex-row pe-2">
						<div class="flex-fill">
							<h5>
								<ng-container *ngFor="let document of clientQuery.documents">
									<ng-container *ngIf="!trusteeView || document.trusteeView">
										<div class="badge rounded-pill bg-light text-dark me-2 mb-2">
											<ng-container *ngIf="document.sharedUrl">
												<a href="{{ document.sharedUrl }}" target="_blank" class="ps-2">
													{{ document.fileName  | truncate: 50 }}
												</a>
											</ng-container>

											<ng-container *ngIf="!document.sharedUrl">
												<a [routerLink]="" (click)="onDownloadClick(document)" class="ps-2">
													{{ document.fileName  | truncate: 50 }}
												</a>
											</ng-container>
											
											<i *ngIf="!busy" class="fa fa-close btn-hand ps-2" (click)="deleteMessageBox.showEx(document)"></i>
												
											<i *ngIf="busy" class="fa fa-close btn-hand ps-2 text-transparent"></i>

										</div>
									</ng-container>
								</ng-container>
							</h5>
						</div>
					</div>
					<p class="card-text pe-2 pb-6" *ngIf="lastUploadDateTime">
						<small class="text-muted">Last upload {{ lastUploadDateTime | dateSinceSentence }} ago</small>
					</p>
				</div>
			</div>
	
			<!-- Right -->
			<div class="ps-1 pt-2" style="width: 120px">
	
			</div>
		</div>
	</div>

</div>

<app-confirmation-box #deleteMessageBox (yes)="onDelete($event)" title="Delete document?"
	message="Are you sure you want to delete the document?"></app-confirmation-box>

<app-box-file-selector #boxFileSelector (boxFilesSelected)="onBoxFilesSelected($event)" [busy]="busy"></app-box-file-selector>
