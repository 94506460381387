import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { BrandingService } from 'projects/advisor/src/app/core/services/branding.service';
import { BroadcastService } from './broadcast.service';

import { ApplicationUser } from '@global/models/accounts/application-user';
import { CookieHelper } from 'projects/advisor/src/app/shared/helpers/cookie-helper';
import { LoginResponse } from '@global/models/accounts/login-response';
import { UserAccountType } from 'projects/advisor/src/app/core/models/users/user';


@Injectable({ providedIn: 'root' })
export class AuthService {

	private _token: string;
	private _tokenExpiration: moment.Moment;

	public redirectUrl: string = null;
	public loginPopupVisible: boolean = false;

	private _user: ApplicationUser;
	public get user(): ApplicationUser {
		return this._user;
	}

	constructor(
		private _router: Router,
		private _branding: BrandingService,
		private _broadcastService: BroadcastService) {
		this.loadAuthorisation();
		this.loginPopupVisible = false;
	}


	public getToken(): string {
		return this._token;
	}
	public getTokenExpiration(): moment.Moment {
		return this._tokenExpiration;
	}

	public setHangfireCookie(): void {
		CookieHelper.setCookie('HangfireCookie', this._token);
	}

	// Authorisations
	private loadAuthorisation(): void {
		console.log('%c[[Auth-Service]]: Loading authorisations...', 'color: green; font-weight: bold;');

		this._token = null;
		this._tokenExpiration = null;
		this._user = null;

		// Load the Token
		this._token = localStorage.getItem('authToken');
		const tokenExpirationString = localStorage.getItem('authTokenExpirationDate');
		if (tokenExpirationString != null) {
			this._tokenExpiration = JSON.parse(tokenExpirationString);
			this._tokenExpiration = moment(this._tokenExpiration);
		}

		// Application User
		const applicationUserString = localStorage.getItem('applicationUser');
		if (applicationUserString !== null) {
			const obj = JSON.parse(applicationUserString);

			if (obj.staff) {
				// This is an old version of the IApplicationUser..
				// This can be deleted at some stage :P
				if (obj.staff) {
					obj.AccountType = UserAccountType.Staff;
				} else {
					obj.AccountType = UserAccountType.Advisor;
				}
				delete obj['staff'];
			}
			this._user = ApplicationUser.new(obj);
		}

		if (!this.isAuthenticated()) {
			console.log('%c[[Auth-Service]]: No authentication token.', 'color: green; font-weight: bold;');
			this.clearAuthentication();
			return;
		}

		this._branding.organisationId = this.user.organisationId;
		this._branding.invalidCache = true;

		this._broadcastService.connect(this._token);
	}

	public setAuthorisation(result: LoginResponse): void {
		console.log('%cAuth-Guard: Set authorisation...', 'color: green;');
		// Token
		this._token = result.token;
		this._tokenExpiration = moment(result.expirationDateUtc);

		console.log(`Token Expiration: ${this._tokenExpiration}`, 'color: green;');
		
		localStorage.setItem('authToken', this._token);
		localStorage.setItem('authTokenExpirationDate', JSON.stringify(this._tokenExpiration));

		// TFA Token
		if (result.tfaToken) {
			localStorage.setItem('TfaToken', result.tfaToken);
		}

		// Application User
		this._user = ApplicationUser.new(result.applicationUser);
		const applicationUserString = JSON.stringify(this._user);
		localStorage.setItem('applicationUser', applicationUserString);

		this.loginPopupVisible = false;

		this._branding.organisationId = result.applicationUser.organisationId;
		this._branding.invalidCache = true;

		// this.loadIntercom();
		
		this._broadcastService.connect(this._token);
	}

	/*
	private loadIntercom(): void {
		if (this.user) {
			// TODO: This needs to move to login
			window.Intercom('boot', {
				app_id: 'ock7jo2o',
				name: this.user.fullName,
				email: this.user.emailAddress,
				created_at: moment().unix() // Signup date as a Unix timestamp
			});
		}
	}
	*/

	// clear token remove user from local storage to log user out
	clearAuthentication(): void {
		// Token
		this._token = null;
		this._tokenExpiration = null;
		localStorage.removeItem('authToken');
		localStorage.removeItem('authTokenExpirationDate');

		// Application User: Keep this so that if your logged out due to a timeout and a login popup is enabled it doesn't break the underlying page.
		// this._user = null;
		// localStorage.removeItem('applicationUser');
	}


	public showLogin(usePopup: boolean) {
		if (this._router.url === '/login') {
			return;
		}
		if (this._router.url === '/forgot') {
			return;
		}
		if (this._router.url === '/recover') {
			return;
		}
		if (this._router.url === '/welcome') {
			return;
		}
		if (this._router.url === '/test') {
			return;
		}
		if (this._router.url === '/unsubscribe') {
			return;
		}
		if (this._router.url === '/problem') {
			return;
		}
		console.log(`%cAuth Service: showLogin(usePopup: ${usePopup}) called.`, 'color: green;');

		if (usePopup) {
			this.loginPopupVisible = false;
			this.loginPopupVisible = true;
		} else {
			this._router.navigate(['/login']);
		}
		this.clearAuthentication();
	} // End showLogin


	public logout() {
		this._broadcastService.disconnect();
		this.clearAuthentication();
	}

	//
	// Authenitication
	//
	public isAuthenticated(): boolean {
		if (this._token == null || this._tokenExpiration == null) {
			return false;
		}
		if (moment.utc().isSameOrAfter(this._tokenExpiration)) {
			return false;
		}
		return true;
	}

	public isStaff(): boolean {
		if (!this.user) {
			return false;
		}
		return this.user.accountType === UserAccountType.Staff;
	}

	public isAdvisor(): boolean {
		if (!this.user) {
			return false;
		}
		return this.user.accountType === UserAccountType.Advisor;
	}

	public isTrustee(): boolean {
		if (!this.user) {
			return false;
		}
		return this.user.accountType === UserAccountType.Trustee;
	}
	
	
	public isDeveloper(): boolean {
		if (!this.user) {
			return false;
		}
		return this.user.id === 628;
	}
}
