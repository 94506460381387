import { EventEmitter, Output, Input, Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

/*
  Provides a base class for all of the detail pages that connects to a service.
  It is a child component because it re-raises the busy and error to the parent to handle.

  busy (in/out)
  error (out)
*/
@Component({
	selector: 'app-child-base',
	template: '<div></div>'
})
// tslint:disable-next-line:component-class-suffix
export abstract class ChildComponentBase {

	@Output() errorChange: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

	private _busy: boolean = false;
	@Input() set busy(value: boolean) {
		if (this._busy !== value) {
			this._busy = value;
			this.busyChange.emit(this._busy);
		}
	}
	get busy(): boolean {
		return this._busy;
	}
	@Output() busyChange: EventEmitter<boolean> = new EventEmitter<boolean>();


	constructor() {
		// ..
	}


	onError(error: HttpErrorResponse) {
		this.busy = false;
		this.raiseError(error);
	}

	raiseError(error: HttpErrorResponse) {
		this.busy = false;
		this.errorChange.emit(error);
	}
	
	clearError(): void {
		this.errorChange.emit(null);
	}
}
