import { Component, Input, OnInit } from '@angular/core';
import { ColorService } from '@global/services/color-service';

//
// This is a simple control used to display the users face in a circle.
//
@Component({
	selector: 'app-user-image',
	templateUrl: './user-image.component.html',
	styleUrls: ['./user-image.component.css']
})
export class UserImageComponent implements OnInit {
	@Input() public url: string;
	@Input() public fullName: string;
	@Input() public title: string;
	@Input() public large: boolean = false;

	public backgroundColor: string;

	constructor(private _colorService: ColorService) {

	}

	ngOnInit() {
		if (this.fullName) {
			this.backgroundColor = this._colorService.getRandomColor(this.fullName);
		} else {
			this.backgroundColor = 'rgb(230, 230, 230)';
		}
	}

	public onError(event: Event) {
		// This will swallow the error quietly and just display initials.
		console.log(`Failed to load the URL ${this.url}`);
		this.url = null;
	}
}
