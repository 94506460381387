import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BoxAccess } from '@global/models/client-queries/box-access';
import { BoxFile } from '@global/models/client-queries/box-file.js';

import { ClientQueryDocumentService } from '@global/services/client-query-document.service.js';

// import * as Box from 'box-ui-elements';
import 'box-ui-elements/dist/picker';
import { AuthService } from '@global/auth/auth.service.js';
import { ModalComponentBase } from '@global/bases/modal-component-base';

declare var Box: any;

// encapsulation: ViewEncapsulation.None is needed otherwise it doesn't display the box css.
@Component({
	selector: 'app-box-file-picker',
	templateUrl: './box-file-picker.component.html',
	styleUrls: ['./box-file-picker.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class BoxFilePickerComponent extends ModalComponentBase implements OnInit {
	@Input() public boxFolderId: string;
	private accessToken: string = null;

	@Output() boxFilesSelected: EventEmitter<BoxFile[]> = new EventEmitter<BoxFile[]>();

	constructor(
		public _auth: AuthService,
		public _service: ClientQueryDocumentService) {
		super();
	}

	ngOnInit(): void {
		this.populate();
	}

	populate(): void {
		console.log(`populate(${this.accessToken})`);
		if (this.accessToken != null) {
			return; // Already loaded the access token...
		}

		this.error = null;
		this.busy = true;
		this._service.getBoxAccessToken().subscribe(
			(item: BoxAccess) => {
				this.busy = false;
				this.accessToken = item.accessToken;
				this.showBoxFilePicker();
			}, (error: HttpErrorResponse) => {
				this.processError(error);
			}
		);
	}

	showBoxFilePicker(): void {
		console.log(`showBox() Folder: ${this.boxFolderId} Access Token: ${this.accessToken}`);
		const filePicker = new Box.FilePicker();
		const _this = this;

		filePicker.addListener('choose', (files: BoxFile[]) => {
			console.log('selectBoxFiles:', files);
			_this.boxFilesSelected.emit(files);
			_this.close();
		});

		filePicker.addListener('cancel', (event) => {
			console.log('Cancel');
			_this.close();
		});

		filePicker.show(_this.boxFolderId, _this.accessToken, {
			container: '.box-container',
			chooseButtonLabel: 'Save',
			cancelButtonLabel: 'Cancel',
			logoUrl: './assets/images/box-logo.png',
			canUpload: false,
			canSetShareAccess: false,
			canCreateNewFolder: false,
			maxSelectable: 50
		});
	}
}
