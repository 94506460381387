//
// This holds details about the currently logged in user.
// It is stored in the AuthService.
//
// It is gathered from home.js (line 50ish or from api/v3/Accounts)

import { UserAccountType, UserPositionType } from 'projects/advisor/src/app/core/models/users/user';

export class ApplicationUser {
	public id: number;
	
	public organisationId: number;
	public organisationName: string;
	
	public fullName: string;
	public pictureUrl: string;
	public accountType: UserAccountType;

	public position: UserPositionType;
	public admin: boolean;
	public lastLoginDate: string;
	public emailAddress: string;

	public clientAccount: boolean;
	
	static new(obj: any): ApplicationUser {
		const item = new ApplicationUser();
		Object.assign(item, obj);

		return item;
	}

	get staff(): boolean {
		return (this.accountType === UserAccountType.Staff);
	}

	get advisor(): boolean {
		return (this.accountType === UserAccountType.Advisor);
	}

	get trustee(): boolean {
		return (this.accountType === UserAccountType.Trustee);
	}

	get developer(): boolean {
		return this.id === 628;
	}
}
