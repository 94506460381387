<!-- Header -->
<div class="card" *ngIf="clientQuery">
	<div class="card-body">

		<div class="d-flex">
			<!-- Title -->
			<h5 class="card-title card-title-back me-auto">{{ clientQuery.entity.name }} - {{ clientQuery.name }}</h5>

			<div class="row">				
				<div class="col-12 col-xl-6 pe-4">
					<small class="text-nowrap">Due Date</small>
					<div>
						<span class="text-nowrap">
							{{ clientQuery.dueDate | shortDate | placeholder  }}					
						</span>
						<!-- Auto Complete Icon -->
						<ng-container *ngIf="clientQuery.autoComplete && _auth.user.staff">
							<span class="fw-bold ps-1" data-toggle="tooltip" title="This query will auto complete on the due date.">
								<i class="h4 fa fa-clock-o text-warning" aria-hidden="true"></i>
							</span>
						</ng-container>
						
					</div>
				</div>
			</div>

		</div>
		
		<app-arrow-path 
			[disabled]="true" 
			[items]="statuses" 
			[status]="clientQuery.status | clientQueryStatusName"
			[fullWidth]="true">
		</app-arrow-path>

	</div>
</div>