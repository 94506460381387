import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppSettingsService } from './services/app-setttings.service';

// Providers

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		AppSettingsService,
		{ provide: 'AppSettingsService', useExisting: AppSettingsService }
	]
})
export class CoreModule {}
