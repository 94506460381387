import { animate, animateChild, animation, state, style, group, keyframes, transition, trigger, stagger, query } from '@angular/animations';

export const commentTextAnimation = trigger('commentTextAnimation', [
	state('small', style({ height: '37.9861px' })),
	state('big', style({ height: '94.6528px' })),
	transition('small => big', [
		animate('500ms', keyframes([
			style({ height: '37.9861px' }),
			style({ height: '94.6528px' }),
		]))
	]),
	transition('big => small', [
		animate('500ms'),
	]),
]);

export const commentButtonDivAnimation = trigger('commentButtonDivAnimation', [
	state('small', style({ height: '0px' })),
	state('big', style({ height: '37.9861px' })),
	transition('small => big', [
		animate('500ms')
	]),
	transition('big => small', [
		animate('500ms'),
	]),
]);

export const commentButtonAnimation = trigger('commentButtonAnimation', [
	state('visible', style({ opacity: '1' })),
	state('hidden', style({ opacity: '0' })),
	transition('hidden => visible', [
		animate('200ms')
	]),
	transition('visible => hidden', [
		animate('500ms')
	]),
]);
