import { EventEmitter, Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { Observable, of } from 'rxjs';

import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

import { AppSettingsFactory } from '@global/services/app-settings-factory';
import { NotificationSearchLine } from 'projects/advisor/src/app/core/models/notifications/notification-search-line';
import { Paginator } from '@global/models/paginator';


@Injectable({ providedIn: 'root' })
export class BroadcastService {
	public connected = false;
	private _connection: HubConnection = null;

	public connectionEstablished = new EventEmitter<boolean>();
	public notificationsRefreshed = new EventEmitter<Paginator<NotificationSearchLine>>();
	public newNotification = new EventEmitter<NotificationSearchLine>();

	constructor(
		private _location: Location,
		private _settings: AppSettingsFactory) {

	}

	public connect(authToken: string): void {
		if (!this._settings.broadcast) {
			return;
		}
		
		if (this.connected) {
			console.log('%c[[Broadcast-Service]] is already connected.', 'color: darkcyan;');
			return;
			// this.disconnect();
		}

		this.createConnection(authToken);
		this.registerOnServerEvents();
		this.startConnection();
	}

	public disconnect(): void {
		if (!this.connected) {
			console.log('%c[[Broadcast-Service]] is not connected.', 'color: darkcyan;');
			return;
		}

		this._connection
			.stop()
			.then(() => {
				console.log('%c[[Broadcast-Service]] connection stopped.', 'color: darkcyan;');
				this.connected = false;
			});
	}

	private createConnection(authToken: string) {
		console.log('%c[[Broadcast-Service]] createConnection()', 'color: darkcyan;');

		let url = '';
		if (this._settings.apiUrl) {
			url = 'https://localhost:44306/defaultHub';
			console.log(`%c[[Broadcast-Service]]: ${url}`, 'color: darkcyan;');
		} else {
			url = '/defaultHub';
			console.log(`%c[[Broadcast-Service]] (production): ${url}`, 'color: darkcyan;');
		}

		this._connection = new HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: () => authToken })
			.build();
	}

	private startConnection(): void {
		console.log('%c[[Broadcast-Service]] startConnection()', 'color: darkcyan;');
		
		this._connection
			.start()
			.then(() => {
				console.log('%c[[Broadcast-Service]] connection started.', 'color: darkcyan;');
				this.connected = true;
				this.connectionEstablished.emit(true);
			});
	}

	private registerOnServerEvents(): void {
		console.log('%c[[Broadcast-Service]] registerOnServerEvents()', 'color: darkcyan;');

		this._connection.on('NewNotification', (data: any) => {
			console.log(`%c[[Broadcast-Service]] NewNotification()`, 'color: darkcyan;');
			const workatoData: NotificationSearchLine = data as NotificationSearchLine;
			this.newNotification.emit(workatoData);
		});

		this._connection.on('RefreshedNotifications', (data: Paginator<NotificationSearchLine>) => {
			console.log(`%c[[Broadcast-Service]] RefreshedNotifications()`, 'color: darkcyan;');
			this.notificationsRefreshed.emit(data);
		});
	}

	refreshNotifications(): Observable<void> {
		console.log(`%c[[Broadcast-Service]] refreshNotifications()`, 'color: darkcyan;');

		const observable = new Observable<void>((observer: any) => {
			this._connection.invoke('RefreshNotifications')
				.then((result: any) => {
					// console.log('Notifications.RefreshNotifications() called...');
				});
		});

		return observable;
	}

	addTestNotification(): Observable<void> {
		console.log(`$c[[Broadcast-Service]] addTestNotification()`, 'color: darkcyan;');

		const observable = new Observable<void>((observer: any) => {
			this._connection.invoke('AddTestNotification')
				.then((result: any) => {
					console.log('addTestNotification called...');
				});
		});


		return observable;
	}
}
