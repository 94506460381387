import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';


import { DetailComponentBase } from '@global/bases/detail-component-base';

import { ClientQuery } from '@global/models/client-queries/client-query';
import { ClientQueryService } from '@global/services/client-query.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: './home.component.html'	
})
export class HomeComponent extends DetailComponentBase implements OnInit {
	public clientQuery: ClientQuery;

	constructor(
		private _service: ClientQueryService,
		private _activatedRoute: ActivatedRoute
		) {
		super();
		
	}

	ngOnInit(): void {
		this._activatedRoute.queryParams.subscribe(params => {
			if (params['query']) {
				const clientQueryGuid = params['query'];
				this.populate(clientQueryGuid);
			}
		});
	}

	populate(clientQueryGuid: string) {
		this.busy = true;
		this.clearError();
		this._service.getByGuid(clientQueryGuid).subscribe(
			(item: ClientQuery) => {
				this.busy = false;
				this.clientQuery = item;
			}, (response: HttpErrorResponse) => {
				this.processError(response);
			}
		);
	}
}