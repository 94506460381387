import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Paginator, SortDirection } from '../models/paginator';

import { ClientQuery, ClientQuerySearchType, ClientQueryStatusType } from '../models/client-queries/client-query';
import { ClientQueryAddComment } from '../models/client-queries/client-query-add-comment';
import { ClientQueryCreate } from '../models/client-queries/client-query-create';
import { ClientQueryStatusUpdate } from '../models/client-queries/client-query-status-update';
import { AdvancedClientQuerySearch } from '../models/client-queries/advanced-client-query-search';

import { ClientQuerySearchLine } from '../models/client-queries/client-query-search-line';
import { ClientQueryDescriptionUpdate } from '../models/client-queries/client-query-description-update';
import { ClientQueryUpdate } from '../models/client-queries/client-query-update';
import { ClientQueryAdvisorUpdate } from '../models/client-queries/client-query-advisor-update';
import { ClientQueryCreatePrefill } from '../models/client-queries/client-query-prefill';
import { ClientQueryBulkEditList } from '../models/client-queries/client-query-bulk-edit-list';
import { DateRangeHelper, FutureDateRangeCriteria, PastDateRangeCriteria } from '../models/date-range';

import * as moment from 'moment';
import { SendToTrusteeRequest } from '@global/models/client-queries/send-to-trustee-request';
import { ClientQueryUpdateTrusteeView } from '@global/models/client-queries/client-query-update-trustee-view';

const momentConstructor: (value?: any) => moment.Moment = (moment as any).default || moment;

export enum ClientQuerySearchOrderBy {
	NotSpecified = 0,
	Entity,
	Organisation,
	Job,
	Name,
	Status,
	RecordType,
	IntelloTeamAssigned,
	AdvisorAssigned,
	DateLastModified,
	ModifiedBy,
}

@Injectable({ providedIn: 'root' })
export class ClientQueryService {
	private baseUrl = `/ClientQueries`;

	constructor(private _httpClient: HttpClient) {
		// Nothing to do here.
	}

	get(id: number): Observable<ClientQuery> {
		const url = `${this.baseUrl}/${id}`;

		return this._httpClient.get<ClientQuery>(url).pipe(
			tap((result: ClientQuery) => {
				console.log(`clientQueries.get(${id})`, result);
			}));
	}

	getByGuid(guid: string): Observable<ClientQuery> {
		const url = `${this.baseUrl}/GetByGuid?guid=${guid}`;

		return this._httpClient.get<ClientQuery>(url).pipe(
			tap((result: ClientQuery) => {
				console.log(`clientQueries.getByGuid(${guid})`, result);
			}));
	}

	loadFromTemplate(organisationId: number, entityId: number, templateId: number, jobId: number) {
		let url = `${this.baseUrl}/loadFromTemplate?organisationId=${organisationId}&entityId=${entityId}`;

		if (templateId != null) {
			url = `${url}&templateId=${templateId}`;
		}

		if (jobId != null) {
			url = `${url}&jobId=${jobId}`;
		}

		return this._httpClient.get<ClientQueryCreate>(url).pipe(
			tap((result: ClientQueryCreate) => {
				console.log(`loadFromTemplate(${organisationId}, ${entityId})`, result);
			}));
	}

	createPrefill(item: ClientQueryCreatePrefill): Observable<ClientQueryCreate> {
		console.log(`createPrefill`, item);
		const url = `${this.baseUrl}/createPrefill`;

		return this._httpClient.post(url, item).pipe(
			tap((result: ClientQueryCreate) => {
				console.log('createPrefill() ', result);
			}));
	}

	create(item: ClientQueryCreate): Observable<ClientQuery> {
		const url = `${this.baseUrl}`;

		return this._httpClient.post(url, item).pipe(
			tap((result: ClientQuery) => {
				console.log('create()', result);
			}));
	}

	update(id: number, item: ClientQueryUpdate): Observable<ClientQuery> {
		const url = `${this.baseUrl}/${id}`;

		return this._httpClient.put(url, item).pipe(
			tap((result: ClientQuery) => {
				console.log('update()', result);
			}));
	}
	
	updateTrusteeView(id: number, item: ClientQueryUpdateTrusteeView): Observable<ClientQuery> {
		const url = `${this.baseUrl}/updateTrusteeView?id=${id}`;

		return this._httpClient.put(url, item).pipe(
			tap((result: ClientQuery) => {
				console.log('updateTrusteeView()', result);
			}));
	}

	updateAdvisor(id: number, item: ClientQueryAdvisorUpdate): Observable<ClientQuery> {
		const url = `${this.baseUrl}/AdvisorUpdate?id=${id}`;

		return this._httpClient.put(url, item).pipe(
			tap((result: ClientQuery) => {
				console.log('updateAdvisor()', result);
			}));
	}
	
	delete(item: ClientQuery): Observable<void> {
		const url = `${this.baseUrl}/${item.id}`;

		return this._httpClient.delete(url).pipe(
			map((result: Response) => {
				console.log('delete()', result);
				return;
			}));
	}

	updateStatus(id: number, status: ClientQueryStatusType): Observable<ClientQuery> {
		const statusUpdate = new ClientQueryStatusUpdate();
		statusUpdate.status = status;

		const url = `${this.baseUrl}/updateStatus?id=${id}`;
		return this._httpClient.put<ClientQuery>(url, statusUpdate);
	}
	

	sendToTrustee(item: SendToTrusteeRequest): Observable<ClientQuery> {
		const url = `${this.baseUrl}/sendToTrustee`;
		return this._httpClient.put<ClientQuery>(url, item);
	}

	updateDescription(id: number, descriptionUpdate: ClientQueryDescriptionUpdate): Observable<void> {
		const url = `${this.baseUrl}/updateDescription?id=${id}`;
		return this._httpClient.put<void>(url, descriptionUpdate);
	}

	updateTrusteeDescription(id: number, descriptionUpdate: ClientQueryDescriptionUpdate): Observable<void> {
		const url = `${this.baseUrl}/updateTrusteeDescription?id=${id}`;
		return this._httpClient.put<void>(url, descriptionUpdate);
	}
	
	bulkUpdate(item: ClientQueryBulkEditList): Observable<void> {
		const url = `${this.baseUrl}/BulkUpdate`;

		return this._httpClient.put(url, item).pipe(
			map((result: Response) => {
				console.log('BulkUpdate()', result);
				return;
			}));
	}

	addComment(id: number, comment: ClientQueryAddComment): Observable<ClientQuery> {
		const url = `${this.baseUrl}/addComment2?id=${id}`;
		return this._httpClient.post<ClientQuery>(url, comment);
	}

	reportProblem(auth: string, clientQueryGuid: string): Observable<void> {
		const url = `${this.baseUrl}/ReportProblem?authGuid=${auth}&clientQueryGuid=${clientQueryGuid}`;
		return this._httpClient.get(url).pipe(
			map((result: Response) => {
				console.log('ReportProblem()', result);
				return;
			}));
	}

	search(
		currentPage: number,
		itemsPerPage: number,
		orderBy: ClientQuerySearchOrderBy,
		sortDirection: SortDirection,

		criteria: string,
		searchType: ClientQuerySearchType,
		organisationId: number,
		entityId: number,
	): Observable<Paginator<ClientQuerySearchLine>> {
		let url = `${this.baseUrl}/Search?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&orderBy=${orderBy}&sortDirection=${sortDirection}&criteria=${criteria}&searchType=${searchType}`;

		if (organisationId) {
			url = url + `&organisationIds=${organisationId}`;
		}
		if (entityId) {
			url = url + `&entityIds=${entityId}`;
		}
		return this._httpClient.get<Paginator<ClientQuerySearchLine>>(url).pipe(
			tap((result: Paginator<ClientQuerySearchLine>) => {
				console.log('ClientQueryService.Search: ', result);
			}));
	}

	searchEx(
		currentPage: number,
		itemsPerPage: number,
		orderBy: ClientQuerySearchOrderBy,
		sortDirection: SortDirection,

		criteria: string,
		searchType: ClientQuerySearchType,

		advancedSearch: AdvancedClientQuerySearch
	): Observable<Paginator<ClientQuerySearchLine>> {
		let url = `${this.baseUrl}/Search?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&orderBy=${orderBy}&sortDirection=${sortDirection}&criteria=${criteria}`;

		if (searchType) {
			url = url + `&searchType=${searchType}`;
		}

		if (advancedSearch) {
			console.log(`searchEx() criteria: ${criteria} searchType: ${searchType}`, advancedSearch);

			if (advancedSearch.organisationIds) {
				for (const id of advancedSearch.organisationIds) {
					url = url + `&organisationIds=${id}`;
				}
			}
			if (advancedSearch.entityIds) {
				for (const id of advancedSearch.entityIds) {
					url = url + `&entityIds=${id}`;
				}
			}
			if (advancedSearch.intelloTeamAssignedUserIds) {
				for (const id of advancedSearch.intelloTeamAssignedUserIds) {
					url = url + `&intelloTeamAssignedUserIds=${id}`;
				}
			}
			if (advancedSearch.advisorAssignedUserIds) {
				for (const id of advancedSearch.advisorAssignedUserIds) {
					url = url + `&advisorAssignedUserIds=${id}`;
				}
			}
			if (advancedSearch.createdByUserIds) {
				for (const id of advancedSearch.createdByUserIds) {
					url = url + `&createdByUserIds=${id}`;
				}
			}
			if (advancedSearch.statuses) {
				for (const id of advancedSearch.statuses) {
					url = url + `&statuses=${id}`;
				}
			}
			if (advancedSearch.recordTypes) {
				for (const id of advancedSearch.recordTypes) {
					url = url + `&recordTypes=${id}`;
				}
			}
			if (advancedSearch.priority) {
				url = url + `&priority=${advancedSearch.priority}`;
			}

			if (advancedSearch.createdDatePreset == PastDateRangeCriteria.Custom) {
				if (advancedSearch.createdDateFrom) {
					url = url + `&createdDateFrom=${advancedSearch.createdDateFrom}`;
				}
				if (advancedSearch.createdDateTo) {
					url = url + `&createdDateTo=${advancedSearch.createdDateTo}`;
				}
			} else if (advancedSearch.createdDatePreset != PastDateRangeCriteria.NotSpecified) {
				const dateString = DateRangeHelper.BuildPastDateString(advancedSearch.createdDatePreset);
				url = url + `&createdDateFrom=${dateString}`;
			}

			if (advancedSearch.dueDatePreset == FutureDateRangeCriteria.Custom) {
				if (advancedSearch.dueDateFrom) {
					url = url + `&dueDateFrom=${advancedSearch.dueDateFrom}`;
				}
				if (advancedSearch.dueDateTo) {
					url = url + `&dueDateTo=${advancedSearch.dueDateTo}`;
				}
			} else if (advancedSearch.dueDatePreset != FutureDateRangeCriteria.NotSpecified) {
				const dateString = DateRangeHelper.BuildFutureDateString(advancedSearch.dueDatePreset);
				url = url + `&dueDateTo=${dateString}`;
			}
		}
		
		return this._httpClient.get<Paginator<ClientQuerySearchLine>>(url).pipe(
			tap((result: Paginator<ClientQuerySearchLine>) => {
				console.log('ClientQueryService.Search: ', result);
			}));
	}
}
