import { Pipe, PipeTransform } from '@angular/core';

import { MomentHelper } from '@global/helpers/moment-helper';

@Pipe({ name: 'shortDate', pure:  true })
export class ShortDatePipe implements PipeTransform {

	transform(value: string, args?: any[]): string {
		return MomentHelper.stringToShortDate(value);
	}
}
