import { Pipe, PipeTransform } from '@angular/core';

import { EntityStatusType } from 'projects/advisor/src/app/core/models/entities/entity';

@Pipe({ name: 'entityStatusName', pure:  true })
export class EntityStatusNamePipe implements PipeTransform {

	public static EntityStatusTypeText = [
		{ value: EntityStatusType.NotSpecified, name: '' },
		{ value: EntityStatusType.Onboarding, name: 'Onboarding' },
		{ value: EntityStatusType.Priors, name: 'Priors' },
		{ value: EntityStatusType.Active, name: 'Active' },
		{ value: EntityStatusType.Offboarding, name: 'Offboarding' },
		{ value: EntityStatusType.Archived, name: 'Archived' },
	];

	transform(value: EntityStatusType, staff: boolean = true): string {
		if (value == null) {
			return '';
		}
		if (value === 0) {
			return 'All';
		}

		// Show Priors and Onboarding as 'Pending'' to Advisors and Trustees ==//==
		if (!staff && (value === EntityStatusType.Priors || value === EntityStatusType.Onboarding)) {
			return EntityStatusNamePipe.EntityStatusTypeText.find(x => x.value === EntityStatusType.Onboarding).name;
		}

		return EntityStatusNamePipe.EntityStatusTypeText.find(x => x.value === value).name;
	}
}
