import { Directive, HostBinding, HostListener } from '@angular/core';


@Directive({
	selector: '[appDropZone]'
})
export class DropZoneDirective {
	
	@HostBinding('class.dragging') dragging: boolean;


	// Drag-Enter
	@HostListener('dragenter', ['$event']) onDragEnter(event) {
		console.log('dragenter');
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	// Drag-Leave
	@HostListener('dragleave', ['$event']) public onDragLeave(event) {
		console.log('dragleave');
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}
}
