<div class="container pt-2">

	<!-- Error -->
	<app-error-alert [error]='error'></app-error-alert>

	<!-- Spinner -->
	<app-page-spinner [busy]="busy" position="top"></app-page-spinner>

	<!-- Header -->
	<div class="row">
		<div class="col">
			<app-home-header [clientQuery]="clientQuery"></app-home-header>
		</div>
	</div>

	<div class="row" *ngIf="clientQuery">
		<div class="col-lg-7 col-md-12 pe-lg-1 pe-md-3">

			<!-- Attachments -->
			<app-client-query-attachments
				[(clientQuery)]="clientQuery" 
				[trusteeView]="true"
				[(busy)]="busy" 
				(errorChange)="onError($event)"></app-client-query-attachments>

			<!-- Description trusteeDescription -->
			<app-client-query-description 
				[(description)]="clientQuery.trusteeDescription" 
				[(busy)]="busy" 
				(errorChange)="onError($event)"></app-client-query-description>
		</div>
		
		<div class="col-lg-5 col-md-12 ps-lg-1 ps-md-3">
			<!-- Activity -->
			<app-client-query-activity 
				[(clientQuery)]="clientQuery" 
				[trusteeView]="true"
				[(busy)]="busy" 
				(errorChange)="onError($event)"></app-client-query-activity>
		</div>
	</div>
</div>