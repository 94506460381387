import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { ArrowPathItem } from './arrow-path-item';

@Component({
	selector: 'app-arrow-path',
	templateUrl: './arrow-path.component.html',
	styleUrls: ['./arrow-path.component.css']
})
export class ArrowPathComponent implements AfterViewChecked, AfterViewInit {

	public tooltip: string = null;

	// Status
	private _status: string;
	@Input() set status(value: string) {
		this._status = value;
		this.populate();
	}
	get status(): string {
		return this._status;
	}
	@Output() statusChange: EventEmitter<ArrowPathItem> = new EventEmitter<ArrowPathItem>();

	// Items
	private _items: ArrowPathItem[];
	@Input() set items(value: ArrowPathItem[]) {
		this._items = value;
		this.populate();
	}
	get items(): ArrowPathItem[] {
		return this._items;
	}

	@Input() busy: boolean;
	@Input() disabled: boolean;

	@Input() fullWidth: boolean;
	@Input() center: boolean;

	@ViewChild('arrowContainer') arrowContainer: ElementRef;
	@ViewChildren('arrowListItem') arrowListItems: QueryList<ElementRef>;

	constructor() {
		this.items = null;
		this.busy = false;
		this.disabled = false;
		this.fullWidth = true;
		this.center = true;
	}

	ngAfterViewInit(): void {
		/*
		var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
		var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  		return new Popover(popoverTriggerEl)
		});
		*/
	}

	ngAfterViewChecked(): void {
		this.onWindowResize(null);
	}

	populate(): void {
		if (this.items) {
			// console.log('ArrowPath:::Populate', this.items, this._status);
			let found = false;
			for (const item of this.items) {
				item.active = false;
				if (!found) {
					item.active = true;
				}
				if (item.text === this._status) {
					// console.log('ArrowPath:::FOUND', item.text, this._status);
					found = true;
				}
			}
		}
	}
	
	onButtonClick(item: ArrowPathItem): void {
		if (!this.busy) {
			// console.log(`Set Status: `, item);
			this.statusChange.emit(item);
		}
	}

	onWindowResize(event) {
		if (this.arrowListItems != null && this.arrowContainer != null) {
			const containerRect = this.arrowContainer.nativeElement.getBoundingClientRect();
			const containerWidth = containerRect.width;
			let width = 0;

			let fullWidth = true;
			this.arrowListItems.forEach(item => {
				const rect = item.nativeElement.getBoundingClientRect();
				width += rect.width;

				if (width <= 140) {
					fullWidth = false;
				}
			});

			// console.log(`containerWidth = ${containerWidth} width = ${width}`);

			// The service is to avoid a ExpressionChangedAfterItHasBeenCheckedError error.
			if (containerWidth > width) {
				// console.log(`Arrow Path: Full Width: True`);
				this.fullWidth = true;
			} else if (!fullWidth) {
				// console.log(`Arrow Path: Full Width: False`);
				this.fullWidth = false;
			}
		}
	}

}
