export enum SendSmsResultType {
	NotSpecified = 0,
	Success,
	AlreadySent
}

export class SendSmsResponse {
	public result: SendSmsResultType;
	public phoneNumberMask: string;
	public message: string;
}
