import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from 'projects/advisor/src/app/core/models/job-statuses/job-status';

@Pipe({ name: 'jobStatusName', pure:  true })
export class JobStatusNamePipe implements PipeTransform {

	transform(value: number, jobStatuses: JobStatus[]): string {
		console.log(`STATUSES +++++++++++ value: ${value}`, jobStatuses);
		if (value == null || jobStatuses == null) {
			return '';
		}

		const jobStatus = jobStatuses.find(x => x.id === value);

		return jobStatus?.name;
	}
}
