<!-- Alerts -->
<ng-container *ngIf="items && items.length">
	<ng-container *ngFor="let item of items">
		<ng-container *ngIf="!trusteeView || item.trusteeView">
			<div class="alert alert-light fade show mb-1" style="background-color: white;" role="alert">
				<div class="d-flex">
					<div class="flex-shrink-0">
						<app-user-image url="{{ item.createdByUser.picture }}" fullName="{{ item.createdByUser.fullName }}">
						</app-user-image>
					</div>

					<div class="flex-grow-1 ms-3">
						<!-- Title: Entity Name -->
						<h5 class="mt-0" *ngIf="clientQuery == null && (entity === null || entity.id !== item.entity.id)">
							<a class=" text-dark text-hover-primary" [routerLink]="['/entities', item.entity.id]">{{item.entity.name}}</a>
						</h5>

						<div>
							<!-- User FullName -->
							<span class="fw-bold">{{ item.createdByUser.fullName }}</span>

							<!-- Action -->
							<span [ngSwitch]="item.action">

								<!-- Created Item -->
								<span *ngSwitchCase="ActivityLogAction.CreatedItem">
									<ng-container *ngIf="!item.documents || item.documents.length === 0">
										created the {{ item | activityLogTargetType }}
									</ng-container>		
									<ng-container *ngIf="!item.documents || item.documents.length === 1">
										created the {{ item | activityLogTargetType }} with 1 attachment
									</ng-container>					
									<ng-container *ngIf="item.documents && item.documents.length > 1">
										created the {{ item | activityLogTargetType }} with {{item.documents.length}} attachments
									</ng-container>
								</span>
								
								<!-- Updated Status -->
								<span *ngSwitchCase="ActivityLogAction.UpdatedStatus">
									updated the status to
									<ng-container *ngIf="item.value">
										<ng-container *ngIf="item.job">
											<span class="fw-bold">{{ item.value | jobStatusName: jobStatuses | placeholder: 'ERROR' }}</span>&nbsp;for the job
										</ng-container>
										<ng-container *ngIf="item.clientQuery">
											<span class="fw-bold">{{ item.value | clientQueryStatusName }}</span>&nbsp;for the query
										</ng-container>
										<ng-container *ngIf="!item.job && !item.clientQuery">
											<span class="fw-bold">{{ item.value | entityStatusName }}</span>&nbsp;for the entity
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!item.value">
										<ng-container *ngIf="item.job">
											<span class="fw-bold">{{ item.valueText }}</span>&nbsp;for the job
										</ng-container>
										<ng-container *ngIf="item.clientQuery">
											<span class="fw-bold">{{ item.valueText}}</span>&nbsp;for the query
										</ng-container>
										<ng-container *ngIf="!item.job && !item.clientQuery">
											<span class="fw-bold">{{ item.valueText }}</span>&nbsp;for the entity
										</ng-container>
									</ng-container>
									<ng-container *ngIf="item.clientQuery == null && item.job == null">
										updated the {{ item | activityLogTargetType }}
									</ng-container>
								</span>

								<!-- Attached Document -->
								<span *ngSwitchCase="ActivityLogAction.AttachedDocument">
									<ng-container *ngIf="item.documents && item.documents.length > 1">
										uploaded {{ item.documents.length }} documents to the {{ item | activityLogTargetType }}
									</ng-container>
									<ng-container *ngIf="!item.documents || item.documents.length === 1">
										uploaded a document to the {{ item | activityLogTargetType }}
									</ng-container>
								</span>

								<span *ngSwitchCase="ActivityLogAction.DeletedAttachment">
									deleted a document from the {{ item | activityLogTargetType }}
								</span>

								<span *ngSwitchCase="ActivityLogAction.WroteComment">
									added a comment to the {{ item | activityLogTargetType }}
								</span>

								<span *ngSwitchCase="ActivityLogAction.RenamedItem">
									renamed the {{ item | activityLogTargetType }} to {{ item.valueText }}
								</span>

								<span *ngSwitchCase="ActivityLogAction.UploadedExpressDocuments">
									<ng-container *ngIf="item.documents && item.documents.length > 1">
										uploaded {{item.documents.length}} express documents to the {{ item | activityLogTargetType }}
									</ng-container>
									<ng-container *ngIf="!item.documents || item.documents.length === 1">
										uploaded an express document to the {{ item | activityLogTargetType }}
									</ng-container>
								</span>

								<span *ngSwitchCase="ActivityLogAction.FollowupMessage">
									requested a followup to the query
								</span>

								<span *ngSwitchCase="ActivityLogAction.AdvisorAssigned">
									<ng-container *ngIf="item.notifications && item.notifications.length === 0">
										assigned <span class="fw-bold">{{ item.valueText }}</span> to the query
									</ng-container>
									<ng-container *ngIf="item.notifications && item.notifications.length > 0">
										assigned <span class="fw-bold" *ngIf="item.notifications[0].recipient != null">{{ item.notifications[0].recipient.name }}</span> to the query
									</ng-container>
								</span>

								<span *ngSwitchCase="ActivityLogAction.ChangedPriority">
									updated the query's priority to <span class="fw-bold">{{ item.value | priorityLevelName }}</span>.
								</span>

								<span *ngSwitchCase="ActivityLogAction.ReminderMessage">
									sent an automated reminder message
									<ng-container *ngIf="item.notifications && item.notifications.length > 0">
										to <span class="fw-bold">{{ item.notifications[0].recipien?.name }}</span>.
									</ng-container>
								</span>


								<span *ngSwitchCase="ActivityLogAction.SendToTrustee">
									<ng-container *ngIf="item.notifications && item.notifications.length > 0">
										sent the {{ item | activityLogTargetType }} to <span class="fw-bold" *ngIf="item.notifications[0].recipient != null">{{ item.notifications[0].recipient?.name }}</span>
									</ng-container>
								</span>

								<div *ngSwitchDefault>
									...
								</div>
							</span>

							<a *ngIf="item.job !== null" [routerLink]="['/jobs', item.job.id]">{{ item.job.name }}</a>

							<a *ngIf="!clientQuery && item.job === null && item.clientQuery !== null" 
								[routerLink]="['/client-queries', item.clientQuery.id]"> {{ item.clientQuery.name }}</a>

							<a *ngIf="item.job === null && item.clientQuery === null && item.entity !== null"
								[routerLink]="['/entities', item.entity.id]">{{ item.entity.name }}</a>

							<!-- Dates -->
							&nbsp;<span class="text-soft">{{ item.createdDate | shortDateTime }}</span>

							<ng-container *ngIf="showEmailNotifications && item.notifications && item.notifications.length > 0">

								<ng-template #popContent let-flag="flag">
									An email notification was sent to
									<ng-container *ngFor="let notification of item.notifications; last as isLast;">
										<span *ngIf="!isLast" class="fw-bold">
											{{ notification.recipient.name }},&nbsp;
										</span>
										<span *ngIf="isLast" class="fw-bold">
											{{ notification.recipient.name }}.
										</span>
									</ng-container>
								</ng-template>

								&nbsp;
								
								<span class="ml-1"
									[ngbPopover]="popContent"
									[openDelay]="20" 
									triggers="mouseenter:mouseleave">
									<i class="fa fa-envelope-o text-info" aria-hidden="true"></i>
								</span>
							</ng-container>

						</div>

						<!-- Comment -->
						<div *ngIf="item.action===ActivityLogAction.WroteComment || item.action===ActivityLogAction.SendToTrustee" style="white-space: pre-wrap;">
							{{ item.comment }}
						</div>

						<!-- Documents -->
						<div *ngIf="item.documents && item.documents.length > 0">
							<ng-container *ngFor="let document of item.documents; let first = first; let last = last;">
								<div>
									<i class="fa fa-file-o ps-4" aria-hidden="true"></i>

									<ng-container *ngIf="document.deleted">
										<span class="ps-3">{{ document.name  | truncate: 50 }}</span>
									</ng-container>

									<ng-container *ngIf="!document.deleted && document.sharedUrl">
										<a href="{{ document.sharedUrl }}" target="_blank" class="ps-2">
											{{ document.name  | truncate: 50 }}
										</a>
									</ng-container>

									<ng-container *ngIf="!document.deleted && !document.sharedUrl">
										<a [routerLink]="" (click)="onDownloadClick(item, document)" class="ps-2">
											{{ document.name  | truncate: 50 }}
										</a>
									</ng-container>
								</div>
							</ng-container>
						</div>
						
						<!-- Deleted Attachment -->
						<div *ngIf="item.action===ActivityLogAction.DeletedAttachment">
							<i class="fa fa-file-o pe-2" aria-hidden="true"></i>{{ item.valueText }}
						</div>
						
						<!-- Followup -->
						<div *ngIf="item.action===ActivityLogAction.FollowupMessage">
							An email notification was sent to 
							<ng-container *ngFor="let notification of item.notifications; last as isLast;">
								<span *ngIf="!isLast" class="fw-bold">
									{{ notification.recipient.name }},&nbsp;
								</span>
								<span *ngIf="isLast" class="fw-bold">
									{{ notification.recipient.name }}.
								</span>
							</ng-container>
						</div>

						<!-- Update Status
						<div *ngIf="item.action === ActivityLogAction.UpdatedStatus && item.clientQuery == null">
							<div *ngIf="item. comment">{{ item. comment.replace('job status ', '') }}</div>
						</div>
						-->
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>