import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';


//
// Checks to see that the user is logged in otherwise redirects them to the login screen.
//
@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

	constructor(
		private _auth: AuthService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute
		) {
	}

	// This seems to get called twice when we are using Hash routing.
	// See: https://stackoverflow.com/questions/40348132/angular-2-canactivate-is-called-twice
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		console.log(`%c[[Auth-Guard]]: Checking ${state.url}...`, 'color: green;');

		if (!this._auth.isAuthenticated()) {
			console.log('%c[[Auth-Guard]]: Not authenticated. Redirecting to /login...', 'color: green;');

			if (state.url !== '/') {
				console.log(`%c[[Auth-Guard]]: redirect URL: ${state.url}`, 'color: green;');
				this._auth.redirectUrl = state.url;
			}

			const org = route.queryParams['org'];
			const auth = route.queryParams['auth'];
			const query = route.queryParams['query'];
			if (auth && query && org) {
				// This is used by the Client project.
				console.log(`authParam: `, auth, query);
				this._router.navigate(['/login'], { queryParams: { org, auth, query } });
			} else {
				this._router.navigate(['/login']);
			}

			return false;
		}

		return true;
	}
}
