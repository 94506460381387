

export class CookieHelper {
	public static EmptyCookie: string = '';

	public static getCookie(name: string) {
		const cookie: Array<string> = document.cookie.split(';');
		const cookieLength: number = cookie.length;
		const cookieName = `${name}=`;

		for (let i: number = 0; i < cookieLength; i += 1) {
			const item = cookie[i].replace(/^\s+/g, '');
			if (item.indexOf(cookieName) === 0) {
				return item.substring(cookieName.length, item.length);
			}
		}

		return CookieHelper.EmptyCookie;
	}

	public static deleteCookie(name: string) {
		CookieHelper.setCookie(name, '', -1);
	}

	public static setCookie(name: string, value: string, expireDays: number = 365) {
		const date: Date = new Date();
		date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
		const expires: string = `expires=${date.toUTCString()}`;
		document.cookie = `${name}=${value}; ${expires}`;
	}
}
