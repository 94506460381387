// Angular
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { GlobalModule } from 'projects/global/src/lib/global.module';
import { CoreModule } from '../core/core.module';

// Components
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProblemComponent } from './problem/problem.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
	declarations: [
		HomeComponent,
		HomeHeaderComponent,
		LoginComponent,
		NavbarComponent,
		ProblemComponent,
		UnsubscribeComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		BrowserModule,
		GlobalModule,
		CoreModule,

		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		NavbarComponent,
	]
})
export class ComponentsModule {

}
