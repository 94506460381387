import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'placeholder', pure:  true })
export class PlaceholderPipe implements PipeTransform {
	transform(value: any, placeholder: string = '-'): any {
		// Changed this to include 0 as thats how it works on the organisation-list screen.
		if (value == null || value === 0 || value === '') {
			return placeholder;
		}

		return value;
	}
}
