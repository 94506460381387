import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';

import { AuthService } from './auth.service';


// This class intercepts all incomming 401 error results and redirects to the login screen.
//
// With a timeout guard the login should be displayed before we end up with a 401 error so
// this is only of use when a user endters a fixed url.
@Injectable({ providedIn: 'root' })
export class Auth401Interceptor implements HttpInterceptor {
	
	constructor(
		private _router: Router,
		private _auth: AuthService,
		@Inject(DOCUMENT) private _document: Document
		) {
		// Nothing to do here.
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(tap(
			(event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// do stuff with response if you want
				}
			},
			(err: any) => {
				if (err instanceof HttpErrorResponse) {
					console.log('%c401 Interceptor: Checking...', 'color: crimson;', this._router.url, err);
					if (err.status === 401) {
						if (this._router.url === '/login') {
							return;
						}
						if (this._router.url === '/logout') {
							return;
						}
						if (this._router.url === '/forgot') {
							return;
						}
						if (this._router.url.startsWith('/unsubscribe')) {
							return;
						}
						if (this._router.url.startsWith('/problem')) {
							return;
						}
						if (this._router.url.startsWith('/recover')) {
							return;
						}
						if (this._router.url.startsWith('/welcome')) {
							return;
						}
						if (this._router.url === '/tests') {
							return;
						}
						// redirect to the login route or show a modal...
						console.log('%c[[401 Interceptor]]: Redirecting to Login...', 'color: crimson; font-weight: bold;');

						this._auth.showLogin(false);
					}
				}
			}
		));
	}
}
