import { Component, EventEmitter, Output } from '@angular/core';
import { BoxFile } from '@global/models/client-queries/box-file.js';

import { ClientQueryDocumentService } from '@global/services/client-query-document.service.js';

import { AuthService } from '@global/auth/auth.service.js';
import { ModalComponentBase } from '@global/bases/modal-component-base';

// encapsulation: ViewEncapsulation.None is needed otherwise it doesn't display the box css.
@Component({
	selector: 'app-box-file-selector',
	templateUrl: './box-file-selector.component.html'
})
export class BoxFileSelectorComponent extends ModalComponentBase {
	public boxFolderId: string = null;

	@Output() boxFilesSelected: EventEmitter<BoxFile[]> = new EventEmitter<BoxFile[]>();

	constructor(
		public _auth: AuthService,
		public _service: ClientQueryDocumentService) {
		super();
	}

	show(boxFolderId: string): void {
		console.log(`show(${boxFolderId})`);
		this.boxFolderId = boxFolderId;
		this.modal.show();
	}

	onBoxFilesSelected(files: BoxFile[]): void {
		this.boxFilesSelected.emit(files);
		this.modal.hide();
	}
}
