
<!-- Box version: takes up the whole page -->
<div *ngIf="busy && !inline" class="spinner-frame">
	<div class="spinner-box" [ngClass]="{'spinner-top': position == 'top', 'spinner-middle': position == 'middle', 'spinner-bottom': position == 'bottom'}">
		<div class="col">
			<div class="row">
				<div class="spinner-grow text-secondary me-1" role="status">
				</div>
				<div class="spinner-grow text-secondary me-1" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<div class="spinner-grow text-secondary" role="status">
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Inline version -->
<div *ngIf="busy && inline" class="text-center pt-4">
	<div class="spinner-grow text-secondary me-1" role="status">
	</div>
	<div class="spinner-grow text-secondary me-1" role="status">
		<span class="sr-only">Loading...</span>
	</div>
	<div class="spinner-grow text-secondary" role="status">
	</div>
</div>