<!-- Modal -->
<app-bootstrap-modal size="medium" centered="true" #modal>
	<div class="model-content">
		<app-page-spinner [busy]="busy"></app-page-spinner>
		<div class="modal-header">
			<h4 class="modal-title">Add Box File(s)</h4>
			<button type="button" class="btn btn-close" (click)="onCloseClick()">
			</button>
		</div>
		<div class="modal-body p-0">
			<ng-container *ngIf="boxFolderId">
			<app-box-file-picker 
				[boxFolderId]="boxFolderId" 
				(boxFilesSelected)="onBoxFilesSelected($event)"></app-box-file-picker>
			</ng-container>
		</div>
	</div>
</app-bootstrap-modal>