// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 3rd Party
import { NgbTooltipModule, NgbDatepickerModule, NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxSummernoteModule } from 'ngx-summernote';

// Services
import { ColorService } from '@global/services/color-service';

// Components
import { ArrowPathComponent } from '@global/components/arrow-path/arrow-path.component';
import { ActivityFeedComponent } from '@global/components/activity-feed/activity-feed.component';
import { BootstrapModalComponent } from '@global/components/bootstrap-modal/bootstrap-modal.component';
import { BoxFilePickerComponent } from '@global/components/box-file-picker/box-file-picker.component';
import { BoxFileSelectorComponent } from '@global/components/box-file-selector/box-file-selector.component';
import { ClientQueryActivityComponent } from '@global/components/client-query-activity/client-query-activity.component';
import { ClientQueryAttachmentsComponent } from '@global/components/client-query-attachments/client-query-attachments.component';
import { ClientQueryDescriptionComponent } from '@global/components/client-query-description/client-query-description.component';
import { ConfirmationBoxComponent } from '@global/components/confirmation-box/confirmation-box.component';
import { ErrorAlertComponent } from '@global/components/error-alert/error-alert.component';
import { PageSpinnerComponent } from '@global/components/page-spinner/page-spinner.component';
import { TextViewerComponent } from '@global/components/text-viewer/text-viewer.component';
import { UserImageComponent } from '@global/components/user-image/user-image.component';

// Directives

import { DropZoneDirective } from '@global/directives/drop-zone.directive';
import { DropZoneContainerDirective } from '@global/directives/drop-zone-container.directive';

// Pipes
import { ActivityLogTargetNamePipe } from '@global/pipes/activity-log-target-name.pipe';
import { ActivityLogTargetTypePipe } from '@global/pipes/activity-log-target-type.pipe';
import { ClientQueryStatusNamePipe } from '@global/pipes/client-query-status-name.pipe';
import { DateSinceSentencePipe } from '@global/pipes/date-since-sentence.pipe';
import { EntityStatusNamePipe } from '@global/pipes/entity-status-name.pipe';
import { InitialsPipe } from '@global/pipes/initials-pipe';
import { JobStatusNamePipe } from '@global/pipes/job-status-name.pipe';
import { LoginResultNamePipe } from '@global/pipes/login-result-name.pipe';
import { ShortDatePipe } from '@global/pipes/short-date.pipe';
import { ShortDateTimePipe } from '@global/pipes/short-date-time.pipe';
import { TruncatePipe } from '@global/pipes/truncate.pipe';
import { PlaceholderPipe } from '@global/pipes/placeholder.pipe';
import { ClientQueryRecordTypeNamePipe } from '@global/pipes/client-query-record-type-name.pipe';
import { PriorityLevelNamePipe } from '@global/pipes/priority-level-name.pipe';

/*
  npm link command?
*/

@NgModule({
	declarations: [
		// Components
		ArrowPathComponent,
		ActivityFeedComponent,
		BootstrapModalComponent,
		BoxFilePickerComponent,
		BoxFileSelectorComponent,
		ClientQueryActivityComponent,
		ClientQueryAttachmentsComponent,
		ClientQueryDescriptionComponent,
		ConfirmationBoxComponent,
		ErrorAlertComponent,
		PageSpinnerComponent,
		TextViewerComponent,
		UserImageComponent,

		// Directives
		DropZoneDirective,
		DropZoneContainerDirective,

		// Pipes
		ActivityLogTargetNamePipe,
		ActivityLogTargetTypePipe,
		ClientQueryRecordTypeNamePipe,
		ClientQueryStatusNamePipe,
		DateSinceSentencePipe,
		EntityStatusNamePipe,
		InitialsPipe,
		JobStatusNamePipe,
		LoginResultNamePipe,
		PlaceholderPipe,
		PriorityLevelNamePipe,
		ShortDatePipe,
		ShortDateTimePipe,
		TruncatePipe,
	],
	exports: [
		// Components
		ArrowPathComponent,
		ActivityFeedComponent,
		BootstrapModalComponent,
		BoxFilePickerComponent,
		BoxFileSelectorComponent,
		ClientQueryActivityComponent,
		ClientQueryAttachmentsComponent,
		ClientQueryDescriptionComponent,
		ConfirmationBoxComponent,
		ErrorAlertComponent,
		PageSpinnerComponent,
		TextViewerComponent,
		UserImageComponent,
		
		// Directives
		DropZoneDirective,
		DropZoneContainerDirective,
		
		// Pipes
		ActivityLogTargetNamePipe,
		ActivityLogTargetTypePipe,
		ClientQueryRecordTypeNamePipe,
		ClientQueryStatusNamePipe,
		DateSinceSentencePipe,
		EntityStatusNamePipe,
		InitialsPipe,
		JobStatusNamePipe,
		LoginResultNamePipe,
		PlaceholderPipe,
		PriorityLevelNamePipe,
		ShortDatePipe,
		ShortDateTimePipe,
		TruncatePipe,
	],
	imports: [
		RouterModule,

		// Angular Modules
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		
		// 3rd Party
		NgxSummernoteModule,
		Ng2GoogleChartsModule,

		NgbTooltipModule, NgbDatepickerModule, NgbPopoverModule, NgbDropdownModule,
	],
	providers: [
		ColorService,
	]
})
export class GlobalModule {

}
