import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { ClientQueryService } from '@global/services/client-query.service';
import { ErrorHelper } from '@global/helpers/error-helper';

@Component({
	templateUrl: './problem.component.html',
})
export class ProblemComponent implements OnInit {
	public busy: boolean = false;
	public error: HttpErrorResponse | string = null;
	public success: boolean = false;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _service: ClientQueryService) {
		
	}

	ngOnInit(): void {
		this._activatedRoute.queryParams.subscribe(params => {
			if (params['query'] && params['auth']) {
				const clientQueryGuid = params['query'];
				const authGuid = params['auth'];
				this._service.reportProblem(authGuid, clientQueryGuid).subscribe(
					(response: any) => {
						this.success = true;
						this.busy = false;
					}, 
					(error: HttpErrorResponse) => {
						this.success = false;
						this.busy = false;
						this.error = ErrorHelper.processError(error);
					}
				);
			}
		});
	}
}
