import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CacheService } from '@global/services/cache.service';
import { SendSmsRequest } from '../models/send-sms-request';
import { SmsLoginRequest } from '../models/sms-login-request';
import { LoginResponse } from '@global/models/accounts/login-response';
import { SendSmsResponse } from '@client/core/models/send-sms-response';


@Injectable({ providedIn: 'root' })
export class SmsAccountService {
	private baseUrl = '/Accounts';

	constructor(
		public _cacheService: CacheService,
		private _httpClient: HttpClient
	) {
		// Nothing to do here.
	}

	sendSmsCode(item: SendSmsRequest): Observable<SendSmsResponse> {
		const url = `${this.baseUrl}/SendSmsCode`;

		return this._httpClient.post(url, item).pipe(
			tap((result: SendSmsResponse) => {
				console.log('SendSmsCode: ', result);
			})
		);
	}

	loginWithSmsCode(item: SmsLoginRequest): Observable<LoginResponse> {
		const url = `${this.baseUrl}/LoginWithSmsCode`;

		return this._httpClient.post(url, item).pipe(
			tap((result: LoginResponse) => {
				console.log('LoginWithSmsCode: ', result);
			})
		);
	}

	unsubscribe(auth: string): Observable<void> {
		const url = `${this.baseUrl}/Unsubscribe?auth=${auth}`;
		return this._httpClient.delete(url).pipe(
			map((result: Response) => {
				console.log('Unsubscribe()', result);
				return;
			}));
	}
}
