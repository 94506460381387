import { Pipe, PipeTransform } from '@angular/core';
import { LoginResultType } from '@global/models/accounts/login-response';

import { MomentHelper } from '@global/helpers/moment-helper';
import * as moment from 'moment';


@Pipe({ name: 'loginResultName', pure:  true })
export class LoginResultNamePipe implements PipeTransform {

	public static IntelloTeamRoleText = [
		{ value: LoginResultType.NotSpecified, name: '' },
		{ value: LoginResultType.Success, name: '' },
		{ value: LoginResultType.TfaRequired, name: 'Authentication Code required.' },
		{ value: LoginResultType.InvalidUserNameOrPassword, name: 'Invalid user name or password.' },
		{ value: LoginResultType.InvalidTfaCode, name: 'Invalid Authentication code.' },
		{ value: LoginResultType.AccountLocked, name: 'The account has been locked.' },
		{ value: LoginResultType.AccountDisabled, name: 'The account has been disabled.' },
		{ value: LoginResultType.RecoveryAccountTokenExpired, name: 'The invitation has expired.' },
		{ value: LoginResultType.AccountNotRegistered, name: 'This account has not accepted the Intello Portal Welcome email invitation. Please check your email inbox and setup the account through the email link.' },
	];

	transform(value: LoginResultType, expirationDateUtc: string = null): string {
		if (value == null) {
			return '';
		}
		// lastUploadDateTime | dateSinceSentence

		if (value === LoginResultType.AccountLocked && expirationDateUtc != null) {
			const dateUtc = moment(expirationDateUtc + '+00:00');
			const date = dateUtc.local();
			return `The account has been locked until ${MomentHelper.toShortDateTime(date)}.`;
		}
		const item = LoginResultNamePipe.IntelloTeamRoleText.find(x => x.value === value);

		if (!item) {
			return '';
		}

		return item.name;
	}
}
