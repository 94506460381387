import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ChildComponentBase } from '@global/bases/child-component-base';

import { BoxFileSelectorComponent } from '../box-file-selector/box-file-selector.component';

import { ClientQuery } from '@global/models/client-queries/client-query';
import { BoxFile } from '@global/models/client-queries/box-file';
import { AuthService } from '@global/auth/auth.service';

import { ClientQueryDocumentService } from '@global/services/client-query-document.service';
import { ClientQueryDocument } from '@global/models/client-queries/client-query-document';

import * as moment from 'moment';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-client-query-attachments',
	templateUrl: './client-query-attachments.component.html',
	styleUrls: ['./client-query-attachments.component.css']
})
export class ClientQueryAttachmentsComponent extends ChildComponentBase implements OnInit {
	public lastUploadDateTime: string;

	@Input() trusteeView: boolean = false;
	
	private _clientQuery: ClientQuery;
	@Input() public set clientQuery(value: ClientQuery) {
		this._clientQuery = value;
		this.populate();
	}
	public get clientQuery(): ClientQuery {
		return this._clientQuery;
	}
	@Output() clientQueryChange: EventEmitter<ClientQuery> = new EventEmitter<ClientQuery>();

	@ViewChild('fileInput', { static: true }) fileInput: any;
	@ViewChild('boxFileSelector') boxFileSelector: BoxFileSelectorComponent;


	constructor(
		public _auth: AuthService,
		public _service: ClientQueryDocumentService) {
		super();
		this.clientQuery = null;
	}

	ngOnInit(): void {

	}

	populate(): void {
		// console.log('populate()', this.clientQuery?.documents);
		// Calculates the last upload date and displays it.
		if (this.clientQuery == null) {
			this.lastUploadDateTime = null;
			return;
		}

		let lastMoment = null;
		let lastDate = null;
		for (const document of this.clientQuery.documents) {
			const createdDate = moment(document.createdDate);

			if (lastMoment == null) {
				lastMoment = createdDate;
				lastDate = document.createdDate;
			} else {
				if (createdDate.isAfter(lastMoment)) {
					lastMoment = createdDate;
					lastDate = document.createdDate;
				}
			}
		}

		this.lastUploadDateTime = lastDate;
	}

	onDownloadClick(document: ClientQueryDocument): void {
		console.log('onDownloadClick()', document);
		
		this.busy = true;

		this._service.downloadTempFile(this.clientQuery.id, document.id).subscribe(
			blob => {
				FileSaver.saveAs(blob, document.fileName);
				this.busy = false;
			},
			(error: HttpErrorResponse) => {
				this.busy = false;
				this.raiseError(error);
			}
		);
	}

	onFilesDropped(files: FileList): void {
		console.log('onFilesDropped()', files);
		this.uploadFiles(files);
	}

	onUploadFiles(event: any): void {
		const files: FileList = event.srcElement.files;
		console.log('onUploadFiles()', files);
		this.uploadFiles(files);
	}

	uploadFiles(files: FileList): void {
		if (files.length > 0) {
			this.busy = true;
			this._service.uploadDocuments(this.clientQuery.id, files, this.trusteeView)
				.subscribe(
					(item: ClientQuery) => {
						if (this.trusteeView) {
							// Edge case where we havn't commited to the TrusteeView
							item.trusteeView = true;
						}
						this.clientQueryChange.emit(item);
						this.busy = false;
					}, (error: HttpErrorResponse) => {
						this.busy = false;
						this.raiseError(error);
					}
				);
		}
	}

	public onBoxFilesSelected(files: BoxFile[]): void {
		console.log('onUploadBoxFiles()', files);

		if (files.length > 0) {
			this.busy = true;
			this._service.uploadBoxDocuments(this.clientQuery.id, files)
				.subscribe(
					(item: ClientQuery) => {
						if (this.trusteeView) {
							// Edge case where we havn't commited to the TrusteeView
							item.trusteeView = true;
						}
						this.clientQueryChange.emit(item);
						this.busy = false;
					}, (error: HttpErrorResponse) => {
						this.busy = false;
						this.raiseError(error);
					}
				);
		}
	}

	onDelete(item: ClientQueryDocument): void {
		console.log('onDelete()', item);

		this.busy = true;

		if (this.trusteeView) {
			// Don't delete the document, just mark it as not in ClientView
			item.trusteeView = false;

			this._service.updateDocument(item).subscribe(
				(data: ClientQuery) => {
					if (this.trusteeView) {
						// Edge case where we havn't commited to the TrusteeView
						item.trusteeView = true;
					}
					this.clientQueryChange.emit(data);
					this.busy = false;
				},
				(error: HttpErrorResponse) => {
					this.busy = false;
					this.raiseError(error);
				}
			);
			return;
		}

		this._service.deleteDocument(item).subscribe(
			(data: ClientQuery) => {
				if (this.trusteeView) {
					// Edge case where we havn't commited to the TrusteeView
					item.trusteeView = true;
				}
				this.clientQueryChange.emit(data);
				this.busy = false;
			},
			(error: HttpErrorResponse) => {
				this.busy = false;
				this.raiseError(error);
			}
		);
	}

	public boxUploadClick(): void {
		this.boxFileSelector.show(this.clientQuery.entity.boxFolderId);
	}
}

