<div class="card mt-2" *ngIf="clientQuery">

	<div class="card-body">
		<!-- Title -->
		<h5 class="card-title">Activity</h5>

		<!-- Comment Text -->
		<textarea class="form-control" 
			placeholder="Add comment..."
			[rows]="1"
			[(ngModel)]="comment"
			(focus)="onTextFocus()"
			(focusout)="onTextFocusOut()"
			[readonly]="busy"
			[@commentTextAnimation]="firstAnimation ? 'big' : 'small'"
			(@commentTextAnimation.done)="onFirstAnimationDone()"
			[style.resize]="firstAnimation ? 'both' : 'none'">
			</textarea>
		
		<div style="height: 0px" 
			[@commentButtonDivAnimation]="secondAnimation ? 'big' : 'small'">
			<div class="d-grid gap-2">
				<button type="button"
					class="btn btn-primary mt-1 ms-0 mb-4" 
					(click)="onAddComment()"
					[disabled]="busy"
					[@commentButtonAnimation]="thirdAnimation ? 'visible' : 'hidden'"
					(@commentButtonAnimation.start)="onThirdAnimationStart()"
					(@commentButtonAnimation.done)="onThirdAnimationDone()"
					>Add Comment</button>
			</div>
		</div>

		<!-- Activity Feed -->
		<div class="mt-4">
			<app-activity-feed
				[showEmailNotifications]="true"
				[clientQuery]="clientQuery" 
				[trusteeView]="trusteeView"
				[items]="clientQuery.activityLogs"></app-activity-feed>
		</div>
	</div>
</div>