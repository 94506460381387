import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

const momentConstructor: (value?: any) => moment.Moment = (moment as any).default || moment;


// 1 minute ago
// 1 minutes ago, 59 minutes ago.
// 1 hour ago, 23 hours ago.
// 1 day ago, 30 days ago.
// 1 month ago, 11 months ago.
// 1 year ago
@Pipe({ name: 'dateSinceSentence', pure:  true })
export class DateSinceSentencePipe implements PipeTransform {

	transform(value: string, args?: any[]): string {
		if (!value) {
			return '';
		}

		const now = moment();
		const date = momentConstructor(value);

		if (date.year() === 1900) {
			return 'a long time';
		}

		if (now.diff(date, 'years') >= 1) {
			const period = now.diff(date, 'years');
			return `${period} year${(period > 1) ? 's' : ''}`;
		} else if (now.diff(date, 'months') >= 1) {
			const period = now.diff(date, 'months');
			return `${period} month${(period > 1) ? 's' : ''}`;
		} else if (now.diff(date, 'weeks') >= 1) {
			const period = now.diff(date, 'weeks');
			return `${period} week${(period > 1) ? 's' : ''}`;
		} else if (now.diff(date, 'days') >= 1) {
			const period = now.diff(date, 'days');
			return `${period} day${(period > 1) ? 's' : ''}`;
		} else if (now.diff(date, 'hours') >= 1) {
			const period = now.diff(date, 'hours');
			return `${period} hour${(period > 1) ? 's' : ''}`;
		} else if (now.diff(date, 'minutes') >= 1) {
			const period = now.diff(date, 'minutes');
			return `${period} minute${(period > 1) ? 's' : ''}`;
		} else {
			return 'less than a minunte';
		}
	}
}
