<ng-container *ngIf="_auth.user && branding">
	<nav class="navbar" style="background-color: pink;" [attr.style]="('background-color:' + branding.primaryColor + ' !important')">
		<div class="container-fluid">
			<div class="nav justify-content-start">
				<span class="navbar-brand p-0">
					<img [src]="branding.whiteLogo" style="max-width: 240px;" />
					<span class="h2 navbar-entity text-white">{{ _auth.user?.organisationName }}</span>
				</span>
			</div>

			<div *ngIf="!_settings.production" class="nav justify-content-end">
				<div class="text-muted">
					version: {{ this._settings.version }}
				</div>
			</div>

			<div *ngIf="_auth.isAuthenticated()" class="nav justify-content-end">
				<a class="navbar-brand h1 btn-hand" routerLink="/logout">
					<i class="fa fa-2x fa-sign-out text-white btn-hand" aria-hidden="true" title="Logout"></i>
				</a>
			</div>
		</div>
	</nav>
</ng-container>
