import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppSettingsFactory } from '@global/services/app-settings-factory';


/**
 * This class intercepts all outgoing HtmlClient calls and injects the api URL at the start.
 */
@Injectable({ providedIn: 'root' })
export class ApiUrlInterceptor implements HttpInterceptor {

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _settings: AppSettingsFactory) {
		// Nothing to do here.
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let url = request.url;

		if (request.url !== './config.json') {
			// Update the url
			let domain = this._document.location.origin;

			if (this._settings.apiUrl) {
				domain = this._settings.apiUrl;
				console.log(`%c Settings domain: ${domain}`, 'color: Gainsboro;');
			}

			url = this.isAbsoluteUrl(request.url) ? request.url : `${domain}/api/v3/${request.url}`;
			url = url.replace(/([^:]\/)\/+/g, '$1');
			// console.log(`%c Url: ${url}`, 'color: Gainsboro;');
		}

		request = request.clone({ url });
		return next.handle(request);
	}

	private isAbsoluteUrl(url: string): boolean {
		const absolutePattern = /^https?:\/\//i;
		return absolutePattern.test(url);
	}
}
