import { Address } from '../address';
import { ClientQueryDefaultAllocation } from '../client-query-default-allocations/client-query-default-allocation';
import { IntelloTeam } from '../intello-teams/intello-team';
import { LookupItem } from '@global/models/lookup-item';

import { ReconciliationFrequencyType } from '../organisations/organisation';
import { EntityNote } from '../entity-notes/entity-note';
import { EntityPerson } from '../entity-persons/entity-person';
import { EntityOrganisation } from '../entity-organisations/entity-organisation';
import { EntityUser } from '../entity-users/entity-user';
import { EntityDataFeed } from './entity-data-feed';
import { EntityBareTrust } from './entity-bare-trust';
import { EntityCorporateTrustee } from './entity-trustee';
import { EntityRecurringService } from '../entity-recurring-services/entity-recurring-service';
import { EntityBillingCategoryAssessment } from './entity-billing-category-assessment';
import { EntityServiceCategory } from '../entity-service-categories/entity-service-category';
import { PaymentDetails } from '../payments/payment-details';
import { LookupOrganisationItem } from '@global/models/lookup-organisation-item';
import { EntityBilling } from './entity-billing';
import { BundledEntityServiceCategory } from '../entity-service-categories/bundled-entity-service-category';

export enum BillingCategoryType {
	NotSpecified = 0,
	Essentials = 1,
	Integrated = 2,
	Plus = 4,
	PremiumPlus = 8

}
export enum EntitySearchType {
	MyEntities = -4,
	All = -3,
	ActivePlusPending = -2,
	Pending = -1,
	NotSpecified = 0,
	Onboarding,
	Priors,
	Active,
	Offboarding,
	Archived,
}

export enum EntityStatusType {
	NotSpecified = 0,
	Onboarding,
	Priors,
	Active,
	Offboarding,
	Archived,
}

export enum EntityStructureType {
	NotSpecified = 0,
	SMSF,
	Trust,
	Individual,
	Company,
	NonSMSF,
	Incubator,
}

export enum EntityOnboardingReason {
	NotSpecified = 0,
	Establishment = 1,
	Transition = 2,
}

export enum EntityOffboardingReason {
	NotSpecified = 0,
	WindUp = 1,
	LeavingIntello = 2,
	LeavingOrganisation  = 3
}

export enum EntityAlertType
{
	NotSpecified = 0,

	BoxFolderNotDefined = 1,
	BoxFolderInvalid = 2,
	BoxFolderNotInOrganisationHierarchy = 4,

	// Class
	InvalidClassFund = 8,
	ClassFundCodeMissmatched = 16,
	ClassFundNameMissmatched = 32,
	ClassFundEntityBusinessCode = 64,

	// Salesforce
	InvalidSalesforceSomething = 128,

	// Xero - Contact / Item
	InvalidXeroContact = 256,
	InvalidXeroItem = 512,
	XeroItemCodeMissmatched = 1024,
	XeroContactCodeMissmatched = 2048,

	// Xpm

	// Payrix
	IntegraPayAccountInvalid = 4096,
	IntegraPayAccountSuspended = 8192,
	IntegraPayAccountCancelled = 16384,

	ManualInvoicing = 32768
}

export enum GstTaxPeriodType
{
	NotSpecified,
	Monthly,
	Quarterly,
	Annually
}

export class Entity {
	public id: number;
	public name: string;
	public code: string;

	public status: EntityStatusType;

	public alertFlags: EntityAlertType;
	
	public primaryOrganisation: LookupOrganisationItem;

	public taxAgent: LookupItem;
	
	// Entity Information
	public abn: string;
	public tfn: string;
	public structure: EntityStructureType;
	public asicRegisteredAgent: boolean;
	public electronicSigning: boolean;
	public asic10YearPrepayment: boolean;
	public reconciliationFrequency: ReconciliationFrequencyType;

	public email: string;
	public phone: string;

	public physicalAddress: Address;
	public postalAddress: Address;

	public corporate: boolean;

	// System Information
	public asfFundCode: string;

	public boxFolderId: string;
	public boxSharedKey: string;
	public classCode: string;
	public classBusinessCode: string;
	public salesforceId: string;
	public xeroItemCode: string;
	public xeroItemId: string;
	public xeroContactId: string;
	public clientView: boolean; // null == Inherit.

	// Read-only
	public registeredForGst: boolean;
	public gstTaxPeriod: GstTaxPeriodType;

	// Payments
	public integraPayId: string; // guid
	public paymentDetails: PaymentDetails; // Lazy loaded

	// Misc
	public billingCategory: BillingCategoryType;
	
	// Objects
	public billing: EntityBilling;
	public defaultAllocations: ClientQueryDefaultAllocation[];
	public intelloTeams: IntelloTeam[];
	public notes: EntityNote[];
	public persons: EntityPerson[];
	public organisations: EntityOrganisation[];
	public users: EntityUser[];
	
	public dataFeeds: EntityDataFeed[];
	public trustee: EntityCorporateTrustee;
	public bareTrust: EntityBareTrust;
	
	public recurringServices: EntityRecurringService[];
	public billingCategoryAssessments: EntityBillingCategoryAssessment[];

	public entityServiceCategories: EntityServiceCategory[];
	public bundledEntityServiceCategories: BundledEntityServiceCategory[];

	constructor() {
		this.intelloTeams = [];

		this.bareTrust = null;
		this.trustee = null;
	}
}
