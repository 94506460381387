import { LookupItem } from '../lookup-item';
import { LookupJobItem } from '@global/models/lookup-job-item';
import { LookupUserItem } from '@global/models/lookup-user-item';
import { ActivityLogDocument } from './activity-log-document';
import { ActivityLogNotification } from './activity-log-notification';


export enum ActivityLogAction {
	NotSpecified = 0,
	CreatedItem = 1,
	UpdatedStatus = 4,
	AttachedDocument = 5,
	DeletedAttachment = 6,
	WroteComment = 7,
	RenamedItem = 8,
	UploadedExpressDocuments = 9,
	FollowupMessage = 10,
	AdvisorAssigned = 11,
	ChangedPriority = 12,
	ReminderMessage = 13,
	SendToTrustee = 14,
}

export class ActivityLog {

	public id: number;
	public action: ActivityLogAction;
	public comment: string;
	public trusteeView: boolean;
	public value: number;
	public valueText: string;
	public createdDate: string;

	public entity: LookupItem;
	public clientQuery: LookupItem;
	public job: LookupJobItem;
	public createdByUser: LookupUserItem;

	public documents: ActivityLogDocument[];
	public notifications: ActivityLogNotification[];
}
