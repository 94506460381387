import { Pipe, PipeTransform } from '@angular/core';

import { ActivityLog } from '@global/models/activity-logs/activity-log';

@Pipe({ name: 'activityLogTargetName', pure:  true })
export class ActivityLogTargetNamePipe implements PipeTransform {

	transform(item: ActivityLog): string {
		if (item.job) {
			return item.job.name;
		} else if (item.clientQuery) {
			return item.clientQuery.name;
		} else if (item.entity) {
			return item.entity.name;
		} else {
			return 'FAILED';
		}
	}
}
