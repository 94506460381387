import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClientQuery } from '@global/models/client-queries/client-query';
import { ClientQueryDocument } from '@global/models/client-queries/client-query-document';
import { BoxAccess } from '@global/models/client-queries/box-access';
import { BoxFile } from '@global/models/client-queries/box-file';

@Injectable({ providedIn: 'root' })
export class ClientQueryDocumentService {
	private baseUrl = `/ClientQueryDocuments`;

	constructor(private _httpClient: HttpClient) {
		// Nothing to do here.
	}

	downloadTempFile(clientQueryId: number, documentId: number): Observable<Blob> {
		const url = `${this.baseUrl}/DownloadTempFile?clientQueryId=${clientQueryId}&documentId=${documentId}`;

		return this._httpClient
			.get(url, { responseType: 'blob', observe: 'response' })
			.pipe(
				map((result: any) => {
					console.log('Object: ', result);

					const blob = new Blob([result.body], { type: result.body.type });
					return blob;
				}));
	}

	uploadDocuments(clientQueryId: number, fileList: FileList, trusteeView: boolean): Observable<ClientQuery> {
		const url = `${this.baseUrl}/uploadDocuments?clientQueryId=${clientQueryId}&trusteeView=${trusteeView}`;

		const files: File[] = Array.from(fileList);

		const formData: FormData = new FormData();

		for (const file of files) {
			formData.append(`formFiles`, file);
		}

		return this._httpClient
			.post(url, formData).pipe(
				map((result: ClientQuery) => {
					return result;
				}));
	}

	uploadBoxDocuments(clientQueryId: number, files: BoxFile[]): Observable<ClientQuery> {
		const url = `${this.baseUrl}/uploadBoxDocuments?clientQueryId=${clientQueryId}`;

		return this._httpClient
			.post(url, files).pipe(
				map((result: ClientQuery) => {
					return result;
				}));
	}

	updateDocument(item: ClientQueryDocument): Observable<ClientQuery> {
		const url = `${this.baseUrl}/${item.id}`;

		return this._httpClient.put(url, item).pipe(
			map((result: ClientQuery) => {
				return result;
			}));
	}

	deleteDocument(item: ClientQueryDocument): Observable<ClientQuery> {
		const url = `${this.baseUrl}/${item.id}`;

		return this._httpClient.delete(url).pipe(
			map((result: ClientQuery) => {
				return result;
			}));
	}

	getBoxAccessToken(): Observable<BoxAccess> {
		const url = `${this.baseUrl}/getBoxAccessToken`;

		return this._httpClient.get<BoxAccess>(url).pipe(
			map((result: BoxAccess) => {
				console.log(`getBoxAccessToken`, result);
				return result;
			}));
	}


}
