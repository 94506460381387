import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';

import { ComponentHelper } from '@global/helpers/component-helper';

@Component({
	selector: 'app-error-alert',
	templateUrl: './error-alert.component.html'
})
export class ErrorAlertComponent {
	@Input() textOnly: boolean = false;

	@Input() set error(value: HttpErrorResponse | string) {
		if (typeof value === 'string') {
			this.errorText = value;
		} else {
			this.errorText = ComponentHelper.processError(value);
		}
	}

	@Input() public errorText: string;

	constructor() {
		
	}
}
