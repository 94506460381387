import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { ComponentHelper } from '@global/helpers/component-helper';

/*
  Provides a base class for all of the detail pages.

  Features:
  * busy (in)
  * error (in)
  * onError()
*/
@Component({
	selector: 'app-detail-base',
	template: '<div></div>'
})
// tslint:disable-next-line:component-class-suffix
export abstract class DetailComponentBase {
	public busy: boolean = false;
	public error: HttpErrorResponse | string = null;

	// Called from the html to process the error message.
	public onError(error: HttpErrorResponse | string) {
		console.log('onError()', error);
		this.error = ComponentHelper.processError(error);
	}

	clearError(): void {
		this.error = null;
	}

	processError(error: HttpErrorResponse | string) {
		this.busy = false;
		this.error = ComponentHelper.processError(error);
	}
}
