<div style="padding-top: 120px; padding-left: 10px; padding-right: 10px;">
	<div style="float: none; margin-left: auto; margin-right: auto; max-width: 600px;">

		<!-- Body -->
		<div class="card shadow-lg">
			<div class="card-body">

				<!-- Error Message -->
				<ng-container *ngIf="error">
					<span class="text-danger">{{ error }}</span>
				</ng-container>
				
				<div *ngIf="success">
					<div class="text-center h3 pb-4 pt-4">
						You have been unsubscribed. 
					</div>
					<p class="text-center">
						We will not contact you again on this email address. 
					</p>
				</div>
			</div>
		</div>
	</div>
</div>