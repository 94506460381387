import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { CacheService } from '../../../../../global/src/app/services/cache.service';

import { Branding } from '../../../../../global/src/app/models/branding/branding';
import { Organisation } from '../models/organisations/organisation';

//
// This is used to load the branding.
// NOTE: There is also an OrganisationBranding service for creating the brands.
//
@Injectable({ providedIn: 'root' })
export class BrandingService implements OnDestroy {
	private baseUrl = '/Branding';

	// Cache
	public invalidCache: boolean = true;
	public organisationId: number = null;
	private subscriptions: Subscription = new Subscription();
	private brandingSubject = new ReplaySubject<Branding>(1);


	constructor(
		private _httpClient: HttpClient,
		private _cacheService: CacheService) {

		const subscription = _cacheService.listen().subscribe(
			(change) => {
				if (!this.invalidCache) {
					console.log('%cBrandingService: Clearing cache.', 'color: cornflowerblue;');
					this.invalidCache = true;
				}
			},
		);
		this.subscriptions.add(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get(): Observable<Branding> {
		if (!this.brandingSubject.observers.length || this.invalidCache) {
			const subdomain = this.getSubdomain();

			if (subdomain == null && (this.organisationId == null || this.organisationId === Organisation.AdministratorOrganisationId)) {
				const defaultBrand = Branding.getDefaultBrand();
				this.brandingSubject.next(defaultBrand);
				// console.log(`Using default Branding.`, data);
				this.brandingSubject.next(defaultBrand);
				this.invalidCache = false;
				return this.brandingSubject;
			}
			
			const url = `${this.baseUrl}/${subdomain}`;
			// console.log(`BrandingService.Get(${subdomain})`);
			this._httpClient.get<Branding>(url).subscribe(
				(data: Branding) => {
					if (data == null) {
						data = Branding.getDefaultBrand();
						// console.log('%c[[BrandingService]]: Using default Branding...', 'color: blue;');
					} else {
						// console.log(`[[BrandingService]]: `, data);
					}
					this.brandingSubject.next(data);
					this.invalidCache = false;
				},
				error => {
					this.brandingSubject.error(error);
					this.brandingSubject = new ReplaySubject(1);
				}
				);
		}

		return this.brandingSubject;
	}

	getSubdomain(): string {
		const domain = window.location.hostname;
		if (domain.indexOf('.') < 0) {
			return null;
		}
		
		const subdomain = domain.split('.')[0];
		if (subdomain === 'login' || subdomain === 'intellodevelopment' || subdomain === 'portal') {
			return null;
		}

		return subdomain;
	}
}
