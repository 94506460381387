import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-text-viewer',
	templateUrl: './text-viewer.component.html',
	styleUrls: ['./text-viewer.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextViewerComponent),
			multi: true
		}
	]
})
export class TextViewerComponent {
	public onChange: any = Function.prototype; 	// Trascend the onChange event
	public onTouched: any = Function.prototype; // Trascend the onTouch event

	@Input() height: number;

	private _value: string;
	get value(): string {
		return this._value;
	}
	set value(value: string) {
		if (this._value !== value) {
			this._value = value;
			this.onChange(value);
		}
	}


	config = {
		tabsize: 2,
		height: null,	// set editor height
		minHeight: 200,	// set minimum height of editor
		maxHeight: 500,	// set maximum height of editor
		focus: false,	// set focus to editable area after initializing
	};

	@ViewChild('textViewer') _formControl: FormControl;

	constructor(private _sanitizer: DomSanitizer) {
		this.value = '';
		this.height = 150;
	}

	// ControlValueAccessor Implmentation...
	public writeValue(value: any): void {
		this.value = value;
	}
	public registerOnChange(fn: (_: any) => {}): void {
		this.onChange = fn;
	}
	public registerOnTouched(fn: () => {}): void {
		this.onTouched = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		// This is always disabled.
	}

	// Validation implementation
	public get errors(): ValidationErrors {
		return this._formControl ? this._formControl.errors : null;
	}
}
