<div class="container-fluid px-0" (window:resize)="onWindowResize($event)">
	<div class="row" [ngClass]="{'text-center': center}">
		<div class="col">
			<!-- Breadcrumbs-->
			<ol *ngIf="items" class="breadcrumb breadcrumb-arrow blue-arrow"
				[ngClass]="{'fullWidth': fullWidth}" #arrowContainer>
			
				<li *ngFor="let item of items; let i = index" #arrowListItem>

					<!-- Disabled versions. -->
					<span *ngIf="(busy || disabled) && item.tooltip" 
						class="text-nowrap blue-arrow" 
						[ngClass]="{'selected': item.active, 'red-arrow': item.color==='red'}" 

						[ngbPopover]="item.tooltip"
						[openDelay]="20" 
						triggers="mouseenter:mouseleave"

						[style.z-index]="100 - i">
						{{ item.text }}
					</span>
					<span *ngIf="(busy || disabled) && !item.tooltip" 
						class="text-nowrap blue-arrow" 
						[ngClass]="{'selected': item.active, 'red-arrow': item.color==='red'}" 

						[style.z-index]="100 - i">						
						{{ item.text }}
					</span>

					<!-- Enabled versions. -->
					<a *ngIf="(!busy && !disabled) && item.tooltip" 
						href="#"
						class="text-nowrap blue-arrow" 
						[ngClass]="{'selected': item.active, 'red-arrow': item.color==='red'}" 
						[routerLink]=""
						(click)="onButtonClick(item)" 
						
						[ngbPopover]="item.tooltip"
						[openDelay]="20" 
						triggers="mouseenter:mouseleave"
						
						[style.z-index]="100 - i">
						{{ item.text }}
					</a>

					<a *ngIf="(!busy && !disabled) && !item.tooltip" 
						href="#"
						class="text-nowrap blue-arrow" 
						[ngClass]="{'selected': item.active, 'red-arrow': item.color==='red'}" 
						[routerLink]=""
						(click)="onButtonClick(item)" 
						
						[style.z-index]="100 - i">
						{{ item.text }}
					</a>
				</li>

			</ol>

		</div>
	</div>
</div>