import { Pipe, PipeTransform } from '@angular/core';

//
// <td>{{ item.name | truncate: 100 }}</td>
//
@Pipe({	name: 'truncate', pure:  true })
export class TruncatePipe implements PipeTransform {

	transform(value: string, limit: number = 25, completeWords: boolean = false, ellipsis: string = '...'): string {
		if (value == null) {
			return '';
		}

		if (completeWords) {
			limit = value.substr(0, limit).lastIndexOf(' ');
		}

		return value.length > limit ? value.substr(0, limit) + ellipsis : value;
	}
}
