import { Pipe, PipeTransform } from '@angular/core';

import { ActivityLog } from '@global/models/activity-logs/activity-log';

@Pipe({ name: 'activityLogTargetType', pure:  true })
export class ActivityLogTargetTypePipe implements PipeTransform {

	transform(item: ActivityLog): string {
		if (item.job) {
			return 'job';
		} else if (item.clientQuery) {
			return 'query';
		} else {
			return 'entity';
		}
	}
}
