import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { SmsAccountService } from '@client/core/services/sms-account.service';
import { ErrorHelper } from '@global/helpers/error-helper';

@Component({
	templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {
	public busy: boolean = false;
	public error: HttpErrorResponse | string = null;
	public success: boolean = false;

	constructor(
		private _activatedRoute: ActivatedRoute,
		public _service: SmsAccountService) {
	}

	ngOnInit() {
		this._activatedRoute.queryParams.subscribe(params => {
			if (params['query'] && params['auth']) {
				const clientQueryGuid = params['query'];
				const authGuid = params['auth'];
				
				this.busy = true;
				this.error = null;
				this._service.unsubscribe(authGuid).subscribe(
					(response: any) => {
						this.busy = false;
						this.success = true;
					}, 
					(error: HttpErrorResponse) => {
						this.success = false;
						this.busy = false;
						this.error = ErrorHelper.processError(error);
					}
				);
			}
		});
	}
}
