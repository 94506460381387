import { Pipe, PipeTransform } from '@angular/core';

import { PriorityLevel } from '@global/models/client-queries/client-query';

@Pipe({ name: 'priorityLevelName', pure:  true })
export class PriorityLevelNamePipe implements PipeTransform {

	public static PriorityLevelText = [
		{ value: PriorityLevel.NotSpecified, name: 'All'},
		{ value: PriorityLevel.Normal, name: 'Normal'},
		{ value: PriorityLevel.High, name: 'High'},
	];

	transform(value: PriorityLevel, args?: any[]): string {
		if (value == null) {
			return '';
		}
		return PriorityLevelNamePipe.PriorityLevelText.find(x => x.value === value).name;
	}
}
