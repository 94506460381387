import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { BootstrapModalComponent } from '@global/components/bootstrap-modal/bootstrap-modal.component';

/*
    Provides a simple message box that is shown using a Bootstrap modal dialog.

	How to use:

		import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
		@ViewChild('deleteConfirmationBox', { static: false }) deleteConfirmationBox: ConfirmationBoxComponent;

<button class="btn" type="button" (click)="deleteMessageBox.show()">Delete</button>

<app-confirmation-box #deleteConfirmationBox (yes)="onConfirmationBoxYes()"
	title="Delete widget?"
	message="Are you sure you want to delete the widget?"></app-confirmation-box>

        onConfirmationBoxYes() : void {
        	// Implement the delete code here.
		}

		<button class="btn" type="button" (click)='deleteMessageBox.showEx(item)'>Delete</button>

        <app-confirmation-box #deleteMessageBox (yes)="onConfirmationBoxYes($event)"
            title="Delete widget?"
            message="Are you sure you want to delete the widget?"></app-confirmation-box>

        onConfirmationBoxYes(item: MyItem) : void {
        	// Implement the delete code here.
		}

 */
@Component({
	selector: 'app-confirmation-box',
	templateUrl: './confirmation-box.component.html'
})
export class ConfirmationBoxComponent {
	@Input() title: string;
	@Input() message: string;
	@Input() size: string; 		// small, medium, large

	@Output() yes: EventEmitter<any> = new EventEmitter<any>();
	@Output() no: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('modal') modal: BootstrapModalComponent;

	private _payload: any;

	constructor() {
		this.title = 'Confirmation Box';
		this.message = 'You need to ask a question here?';
		this.size = 'medium';
	}

	show(): void {
		this._payload = null;
		this.modal.show();
	}

	showEx(payload: any): void {
		console.log('payload: ', payload);
		this._payload = payload;
		this.modal.show();
	}

	yesClicked(): void {
		console.log('Yes Clicked.');
		this.modal.hide();
		if (this._payload) {
			this.yes.emit(this._payload);
		} else {
			this.yes.emit();
		}
	}

	noClicked(): void {
		console.log('No Clicked.');
		if (this._payload) {
			this.no.emit(this._payload);
		} else {
			this.no.emit();
		}
		this.modal.hide();
	}
}
