import { Pipe, PipeTransform } from '@angular/core';

import { ClientQueryStatusType } from '@global/models/client-queries/client-query';

@Pipe({ name: 'clientQueryStatusName', pure:  true })
export class ClientQueryStatusNamePipe implements PipeTransform {

	public static StatusTypeText = [
		{ value: ClientQueryStatusType.NotSpecified, name: 'All'},
		{ value: ClientQueryStatusType.Draft, name: 'Draft'},
		{ value: ClientQueryStatusType.ActionRequired, name: 'Action Required'},
		{ value: ClientQueryStatusType.RespondedTo, name: 'Responded To'},
		{ value: ClientQueryStatusType.Completed, name: 'Completed'},
		{ value: ClientQueryStatusType.Cancelled, name: 'Cancelled'},
	];

	transform(value: ClientQueryStatusType, args?: any[]): string {
		if (value == null)  {
			return '';
		}
		if (value === 0) {
			return 'All';
		}
		return ClientQueryStatusNamePipe.StatusTypeText.find(x => x.value === value).name;
	}
}
