import { Component, Input } from '@angular/core';

/*
	We are using this as the NG Bootstrap control seems a bit primative.
	It might be better now but I haven't had a chance to look at it as yet.

	Taken from: https://stackoverflow.com/questions/34513558/angular-2-0-and-modal-dialog

	<button type="button" class="btn btn-wide btn-outline-primary" [disabled]="busy" (click)="modal.show()">Show</button>

	<app-bootstrap-modal #modal [visible]="_auth.loginPopupVisible">
		<div class="model-content">
			<div class="modal-header">
				<h5 class="modal-title">Login</h5>
			</div>
			<div class="modal-body">
				<p>this is a modal dialog</p>
			</div>
		</div>
	</app-bootstrap-modal>
*/
@Component({
	selector: 'app-bootstrap-modal',
	templateUrl: './bootstrap-modal.component.html',
	styleUrls: ['./bootstrap-modal.component.css']
})
export class BootstrapModalComponent {
	public _visibleAnimate = false;
	public _visible: boolean = false;

	@Input() public centered: boolean = false;   // The modal is centered vertrically on the screen
	@Input() backdropStatic: boolean = false;    // If true the window won't close by clicking outside the modal area.
	@Input() size: string = 'medium';            // small, medium (default), large, x-large, xx-large
	@Input() keyboard: boolean = true;           // Closes the modal when escape key is pressed
	@Input() public scrollable: boolean = false;

	@Input() public zindex: string = '1010';

	@Input()
	set visible(value: boolean) {
		if (value) {
			this.show();
		} else {
			this.hide();
		}
	}

	constuctor() {
		this._visible = false;
		this.size = 'medium';
		this.centered = false; // TODO Centered isn't working.
		this.backdropStatic = false;
		this.keyboard = true;
		this.scrollable = false;
	}

	public show(): void {
		this._visible = true;
		setTimeout(() => this._visibleAnimate = true, 100);
	}

	public hide(): void {
		// console.log('hide modal');
		this._visibleAnimate = false;
		setTimeout(() => this._visible = false, 300);
	}

	public onContainerClicked(event: MouseEvent): void {
		if ((event.target as HTMLElement).classList.contains('modal')) {
			if (!this.backdropStatic) {
				this.hide();
			}
		}
	}
}
