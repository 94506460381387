import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '@global/auth/auth.service';


//
// This class intercepts all outgoing HtmlClient calls and injects the JWT
// Authorisation token if it exists.
//
@Injectable({ providedIn: 'root' })
export class AuthJwtInterceptor implements HttpInterceptor {

	constructor(private _injector: Injector) {
		// Nothing to do here.
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Get the auth header from the authorisation service.
		const auth = this._injector.get(AuthService);

		const token = auth.getToken();

		if (!token) {
			return next.handle(request.clone());
		}

		// console.log('Injecting Token: ', token);
		// Clone the request so we can add the modified header.
		const authReq = request.clone({
			headers: request.headers.set('Authorization', 'Bearer ' + token)
		});

		return next.handle(authReq);
	}
}
