import { Component, Input } from '@angular/core';

/*
<app-page-spinner [busy]="busy" inline="true"></app-page-spinner>

Also see spinner
*/
@Component( {
	selector: 'app-page-spinner',
	templateUrl: './page-spinner.component.html',
	styleUrls: ['./page-spinner.component.css']
})
export class PageSpinnerComponent {
	@Input() public busy: boolean = false;
	@Input() public inline: boolean = false;

	@Input() public position: string = 'bottom'; // top | middle | bottom
}
