import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Paginator, SortDirection } from '@global/models/paginator';
import { map } from 'rxjs/operators';
import { ExpressDocumentSearchLine } from '../models/express-documents/express-document-search-line';

export enum ExpressDocumentSearchOrderBy {
	NotSpecified,
	Id,
	OrganisationName,
	EntityName,
	Name,
	CreatedDate
}

@Injectable({ providedIn: 'root' })
export class ExpressDocumentService {
	private baseUrl = `/ExpressDocuments`;


	constructor(private _httpClient: HttpClient) {
		// Nothing to do here.
	}

	downloadTempFile(clientQueryId: number, documentId: number): Observable<Blob> {
		const url = `${this.baseUrl}/DownloadTempFile?clientQueryId=${clientQueryId}&documentId=${documentId}`;

		return this._httpClient
			.get(url, { responseType: 'blob', observe: 'response' })
			.pipe(
				map((result: any) => {
					console.log('Object: ', result);

					const blob = new Blob([result.body], { type: result.body.type });
					return blob;
				}));
	}
	
	search(currentPage: number, itemsPerPage: number, orderBy: ExpressDocumentSearchOrderBy, sortDirection: SortDirection, criteria: string)
		: Observable<Paginator<ExpressDocumentSearchLine>> {
		const url = `${this.baseUrl}/Search?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&orderBy=${orderBy}&sortDirection=${sortDirection}&criteria=${criteria}`;

		return this._httpClient.get<Paginator<ExpressDocumentSearchLine>>(url);
	}

	upload(entityId: number, files: File[]): Observable<number> {
		console.log(`upload(${entityId})`, files);
		const url = `${this.baseUrl}/upload?entityId=${entityId}`;

		const formData: FormData = new FormData();

		for (const file of files) {
			formData.append(`formFiles`, file);
		}

		const options = {
			observe: 'events' as const,
			params: new HttpParams(),
			reportProgress: true
		  };

		return this._httpClient
			.post(url, formData, options).pipe(
				map((event: HttpEvent<any>) => {
					if (event.type == HttpEventType.UploadProgress) {
						const percentage = Math.ceil((event.loaded / event.total) * 100);
						console.log(`event: ${percentage}`, event);
						return percentage;
					}
				}));
	}
}
