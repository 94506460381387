import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'initials', pure:  true })
export class InitialsPipe implements PipeTransform {

	transform(value: string, args?: any[]): string {
		if (value == null) {
			return '--';
		}
		const initials = value.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '').replace(/\W/g, '');
			
		return initials;
	}
}
