import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '@global/auth/auth-guard.service';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProblemComponent } from './components/problem/problem.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AuthGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: 'logout', component: LogoutComponent },
	{ path: 'problem', component: ProblemComponent },
	{ path: 'unsubscribe', component: UnsubscribeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
}
