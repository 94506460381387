import { ApplicationUser } from '@global/models/accounts/application-user';

export enum LoginResultType {
	NotSpecified = 0,
	Success,
	TfaRequired,

	InvalidUserNameOrPassword,
	InvalidTfaCode,
	AccountLocked,
	AccountDisabled,

	RecoveryAccountTokenExpired,

	AccountNotRegistered
}

export class LoginResponse {
	public result: LoginResultType;

	public token: string;
	public expirationDateUtc: string;

	public tfaToken: string;

	public applicationUser: ApplicationUser;
}
