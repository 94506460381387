import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ChildComponentBase } from '@global/bases/child-component-base';

import { ClientQueryService } from '@global/services/client-query.service';
import { ClientQueryAddComment } from '@global/models/client-queries/client-query-add-comment';
import { ClientQuery } from '@global/models/client-queries/client-query';

import { commentTextAnimation, commentButtonDivAnimation, commentButtonAnimation } from './client-query-activity.animations';

@Component({
	selector: 'app-client-query-activity',
	templateUrl: './client-query-activity.component.html',
	animations: [
		commentTextAnimation,
		commentButtonDivAnimation,
		commentButtonAnimation
	]
})
export class ClientQueryActivityComponent extends ChildComponentBase {

	public comment: string;

	public firstAnimation: boolean = false;
	public secondAnimation: boolean = false;
	public thirdAnimation: boolean = false;

	@Input() trusteeView: boolean = false;
	
	@Input() clientQuery: ClientQuery;
	@Output() clientQueryChange: EventEmitter<ClientQuery> = new EventEmitter<ClientQuery>();

	constructor(private _service: ClientQueryService) {
		super();
		this.comment = '';
		this.clientQuery = null;
	}

	public onAddComment() {
		console.log('onAddComment()', this.comment);
		if (this.comment.trim() === '') {
			return;
		}

		const clientQueryAddComment = new ClientQueryAddComment();
		clientQueryAddComment.comment = this.comment;
		clientQueryAddComment.trusteeView = this.trusteeView;
		
		this.busy = true;
		this._service.addComment(this.clientQuery.id, clientQueryAddComment)
			.subscribe(
				(item: ClientQuery) => {
					if (this.trusteeView) {
						// Edge case where we havn't commited to the TrusteeView
						item.trusteeView = true;
					}
					this.clientQueryChange.emit(item);
					this.comment = '';
					this.busy = false;
					this.firstAnimation = false;
				}, (error: HttpErrorResponse) => {
					this.busy = false;
					this.raiseError(error);
				}
			);
	}

	onTextFocus(): void {
		this.firstAnimation = true;
		this.secondAnimation = true;
	}

	onTextFocusOut(): void {
		if (this.comment.trim().length === 0) {
			this.firstAnimation = false;
			this.thirdAnimation = false;
		}
	}

	onFirstAnimationDone(): void {
		// console.log('onFirstAnimationDone', this.firstAnimation);
		this.thirdAnimation = this.firstAnimation;
	}

	onThirdAnimationStart(): void {
		// console.log('onThirdAnimationStart', this.thirdAnimation);
		// console.log('start================');
	}

	onThirdAnimationDone(): void {
		// console.log('onThirdAnimationDone', this.thirdAnimation);
		// console.log('=================done');
		if (this.thirdAnimation === false) {
			this.secondAnimation = false;
		}
	}
}
