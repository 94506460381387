import { Component, Input } from '@angular/core';

import { ChildComponentBase } from '@global/bases/child-component-base';

@Component({
	selector: 'app-client-query-description',
	templateUrl: './client-query-description.component.html',
	styleUrls: ['./client-query-description.component.css']
})
export class ClientQueryDescriptionComponent extends ChildComponentBase {

	@Input()  description: string;

	constructor() {
		super();
		this.description = null;
	}
}
