
<img *ngIf="url" class="align-self-start rounded-circle user-image" 
	aria-hidden="true"
	[ngClass]="{'user-image-sm': !large, 'user-image-lg': large}"
	src="{{url}}" 
	ngbTooltip="{{title}}" 
	(error)="onError($event)">

<div *ngIf="!url" class="align-self-start user-image-div"
	[ngClass]="{'user-image-div-sm': !large, 'user-image-div-lg': large}"
	[ngStyle]="{'background-color': backgroundColor}"
	ngbTooltip="{{title}}" >
	<span>{{ fullName | initials }}</span>
</div>