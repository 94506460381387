<div style="padding-top: 120px; padding-left: 10px; padding-right: 10px;">
	<div style="float: none; margin-left: auto; margin-right: auto; max-width: 600px;">

		<div class="card shadow-lg">
			<div class="card-body">

				<!-- Error Message -->
				<ng-container *ngIf="error">
					<span class="text-danger">{{ error }}</span>
				</ng-container>

				<ng-container *ngIf="success">
					<div class="text-center h3 pb-4 pt-4">
						We are sorry to hear your having problems with our service.
					</div>
					<p class="text-center">
						We will let the team know that you are having trouble viewing the query.
					</p>
				</ng-container>
			</div>
		</div>
	</div>
</div>