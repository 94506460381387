import { Pipe, PipeTransform } from '@angular/core';

import { ClientQueryRecordType } from '@global/models/client-queries/client-query';

@Pipe({ name: 'clientQueryRecordTypeName', pure:  true })
export class ClientQueryRecordTypeNamePipe implements PipeTransform {

	public static RecordTypeText = [
		{ value: ClientQueryRecordType.NotSpecified, name: 'All'},
		{ value: ClientQueryRecordType.TaxPayments, name: 'Tax Payments'},
		{ value: ClientQueryRecordType.StrategicItems, name: 'Strategic Items'},
		{ value: ClientQueryRecordType.AccountFinalisations, name: 'Account Finalisations'},
		{ value: ClientQueryRecordType.DataFeedAuthority, name: 'Data Feed Authority'},
		{ value: ClientQueryRecordType.ClientQueries, name: 'Client Queries'},
	];

	transform(value: ClientQueryRecordType, args?: any[]): string {
		if (value == null) {
			return '';
		}
		return ClientQueryRecordTypeNamePipe.RecordTypeText.find(x => x.value === value).name;
	}
}
