import { FormControl, NgModelGroup, NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { ErrorHelper } from './error-helper';

export class ComponentHelper {

	public static paramsToNumberArray(params: string | Array<string>) {
		const list: number[] = [];
		if (Array.isArray(params)) {
			for (const child of params) {
				list.push(+child);
			}
		} else {
			const str = params;
			list.push(+str);
		}

		return list;
	}

	public static validateAllFormGroupFields(group: NgModelGroup): void {
		for (const name in group.control.controls) {
			if (name) {
				console.log(`ControlName: ${name}`);
				
				const control = group.control.controls[name];
				if (control instanceof FormControl) {
					control.markAsTouched();
					control.updateValueAndValidity();
				} else if (control instanceof NgForm) {
					// Recursive loop through the controls children.
					this.validateAllFormFields(control);
				}
			}
		}
	}

	// Forces validation on all controls in the form provided.
	public static validateAllFormFields(form: NgForm): void {
		for (const name in form.controls) {
			if (name) {
				const control = form.controls[name];
				if (control instanceof FormControl) {
					control.markAsTouched();
					control.updateValueAndValidity();
				} else if (control instanceof NgForm) {
					// Recursive loop through the controls children.
					this.validateAllFormFields(control);
				}
			}
		}
	}

	public static resetForm(form: NgForm, resetSubmit: boolean = false) {
		console.log('resetForm()');
		for (const name in form.controls) {
			if (name) {
				const control = form.controls[name];
				if (control instanceof FormControl) {
					control.markAsUntouched();
					control.markAsPristine();
				} else if (control instanceof NgForm) {
					// Recursive loop through the controls children.
					this.resetForm(control);
				}
			}
		}
		// I took the following line out as it was resetting the bound items.
		if (resetSubmit) {
			form.resetForm();
		}
	}

	public static processError(response: HttpErrorResponse | string): string {
		return ErrorHelper.processError(response);
	}
}
