import { Address } from '../address';
import { BankAccount } from '../payments/bank-account';
import { ClientQueryDefaultAllocation } from '../client-query-default-allocations/client-query-default-allocation';
import { IntelloTeam } from '../intello-teams/intello-team';
import { LookupItem } from '@global/models/lookup-item';
import { OrganisationBilling } from './organisation-billing';
import { OrganisationBranding } from './organisation-branding';
import { PaymentDetails } from '../payments/payment-details';
import { OrganisationNote } from '../organisation-notes/organisation-note';
import { Contact } from './contact';


export enum OrganisationStatusType {
	NotSpecified = 0,
	Prospect = 1,
	Active = 2,
	Archived = 3,
}

export enum OrganisationClientViewType {
	NotSpecified = 0,
	Class = 1,
	Intello = 2,
	Archived = 3,
}

export enum ReconciliationFrequencyType {
	NotSpecified = 0,
	Daily,
	Monthly,
	Quarterly
}

export enum OrganisationAlertType
{
	NotSpecified = 0,

	/// <summary>.The Organisation.BoxFolderId == null or 0.</summary>
	BoxFolderNotDefined = 1,
	/// <summary>Unable to locate the box folder in the Intello's root folder structure.</summary>
	BoxFolderInvalid = 2,
	/// <summary>Unable to locate the archived box folder in the Intello's root folder structure.</summary>
	BoxFolderArchivedInvalid = 4,

	// Payrix
	/// <summary>The Payrix billing account is invalid.</summary>
	IntegraPayAccountInvalid = 8,
	/// <summary>The Payrix billing account is suspended.</summary>
	IntegraPayAccountSuspended = 16,
	/// <summary>The Payrix billing account is cancelled.</summary>
	IntegraPayAccountCancelled = 32,

	// InvoiceType is set to manual on the organisation.
	ManualInvoicing = 64
}


export enum ReferralType
{
	NotSpecified = 0,
	WebEnquiry,
	AdvisorReferral,
	Conference,
	Other
}

export class Organisation {
	public static AdministratorOrganisationId: number = 204;
	public static IntelloPrivateOrganisationId: number = 239;
	public static AdvisorDemoOrganisationId: number = 216;
	
	public id: number;
	public name: string;

	public status: OrganisationStatusType;
	
	public alertFlags: OrganisationAlertType;

	// Organisation Information
	public licensor: LookupItem;
	public code: string;
	public phone: string;
	public asicRegisteredAgent: boolean;
	public electronicSigning: boolean;
	public reconciliationFrequency: ReconciliationFrequencyType;

	public trusteeQueries: boolean;
	
	public postalAddress: Address;
	public physicalAddress: Address;

	// Marketing
	public referralType: ReferralType;
	public primaryContact: Contact;
	
	// System Information
	public classBusinessCode: string;
	public classBrandCode: string;
	public clientView: boolean; // 0 == class
	public xeroContactId: string;
	public boxFolderId: string;
	public boxSharedKey: string;
	public salesforceId: string;
	
	// Payments
	public integraPayId: string; // guid
	public paymentDetails: PaymentDetails; // Lazy loaded

	// Objects
	public defaultAllocations: ClientQueryDefaultAllocation[];
	public intelloTeams: IntelloTeam[];

	public billing: OrganisationBilling;

	public branding: OrganisationBranding;
	public remittanceBankAccount: BankAccount;

	public notes: OrganisationNote[];

	constructor() {
		this.paymentDetails = null;
		this.defaultAllocations = [];
		this.intelloTeams = [];
	}
}
