<div style="padding-top: 120px; padding-left: 10px; padding-right: 10px;">
	<div style="float: none; margin-left: auto; margin-right: auto; max-width: 600px;">

		<div class="card shadow-lg">
			<div class="card-body">
				<div class="text-center h3 pb-4 pt-4">
					You have logged out.
				</div>
				<p>
					To login again please click the link in the email we sent you.
				</p>
			</div>
		</div>
	</div>
</div>