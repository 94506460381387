
import { LookupItem } from '../lookup-item';
import { LookupJobItem } from '@global/models/lookup-job-item';
import { ClientQueryDocument } from './client-query-document';
import { ActivityLog } from '../activity-logs/activity-log';
import { LookupEntityItem } from '@global/models/lookup-entity-item';
import { ClientQueryStatusLog } from './client-query-status-log';
import { ScheduledPeriodType } from '../../../../../advisor/src/app/core/models/client-query-templates/client-query-template';
import { JobSectionType } from '../../../../../advisor/src/app/core/models/jobs/job';

export enum ClientQuerySearchType {
	NotSpecified = 0,
	Draft,
	Active,
	Closed
}

export enum ClientQueryStatusType {
	NotSpecified = 0,
	Draft,
	ActionRequired,
	RespondedTo,
	Completed,

	Cancelled
}

// tslint:disable:no-bitwise
export enum ClientQueryRecordType {
	NotSpecified = 0,
	AccountFinalisations = 1 << 0,
	ClientQueries = 1 << 1,
	DataFeedAuthority = 1 << 2,
	StrategicItems = 1 << 3,
	TaxPayments = 1 << 4,
}
// tslint:enable:no-bitwise

export enum PriorityLevel {
	NotSpecified = 0,
	Normal = 1,
	High = 2
}

export class ClientQuery {

	public id: number;
	public name: string;
	public description: string;
	public trusteeDescription: string;
	
	public jobSection: JobSectionType;

	public status: ClientQueryStatusType;
	public recordType: ClientQueryRecordType;
	public priority: PriorityLevel;

	public createdDate: string;
	public dueDate: string;

	public autoComplete: boolean;
	public trusteeView: boolean;

	public trusteeQueriesEnabled: boolean;

	public autoReminderSent: number;
	public autoReminderNumber: number;
	public autoReminderPeriod: ScheduledPeriodType;

	public jobWorkflowStepId: boolean;

	public intelloTeamAssigned: LookupItem;
	public advisorAssigned: LookupItem;

	public organisation: LookupItem;
	public entity: LookupEntityItem;
	public job: LookupJobItem;

	public documents: ClientQueryDocument[];
	public activityLogs: ActivityLog[];
	
	public statusLogs: ClientQueryStatusLog[];
}
