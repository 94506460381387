import { UserEntity } from './user-entity';

export enum UserFlagType {
	NotSpecified = 0,
	/// <summary>Defines that it is an account that doesnt use an email and password to login but instead is sent an SMS to their phone number.</summary>
	ClientAccount = 1,
	Unsubscribed = 2,
}

export enum UserAccountType {
	NotSpecified = 0,
	Staff = 1,
	Advisor = 2,
	Trustee = 3
}

export enum UserPositionType {
	NotSpecified = 0,

	// Advisors
	Director = 1,
	Manager = 2,
	AccountantParaPlanner = 3,
	AdvisorClientServicesOfficer = 5,

	// Staff
	RelationshipManager = 6,
	BrandManager = 7,
	Accountant = 8,
	Administration = 10,

	// Trustee
	Trustee = 11
}

export enum AdvisorUserPositionType {
	NotSpecified = 0,

	// Advisors
	Director = 1,
	Manager = 2,
	AccountantParaPlanner = 3,
	AdvisorClientServicesOfficer = 5,
}

export enum StaffUserPositionType {
	NotSpecified = 0,

	// Staff
	RelationshipManager = 6,
	BrandManager = 7,
	Accountant = 8,
	Administration = 10,
}

export enum TrusteeUserPositionType {
	NotSpecified = 0,

	// Staff
	Trustee = 11,
}


export enum UserSummaryEmailFrequency {
	None = 0,
	Daily = 1,
	Weekly = 2,
	Monthly = 3,
	Quarterly = 4
}

export class User {
	public id: number;
	public guid: string;

	public tfaEnabled: boolean;

	public email: string;
	public firstName: string;
	public lastName: string;
	public phone: string;

	public accountType: UserAccountType;
	public clientAccount: boolean;
	public administrator: boolean;
	public position: UserPositionType;

	public picture: string;
	public organisationId: number;

	public primaryContact: boolean;
	public billingContact: boolean;
	public summaryEmailFrequency: UserSummaryEmailFrequency;
	public lastSummaryEmailDate: string;

	public emailConfirmed: boolean;
	public changePassword: boolean;
	public changePasswordSentDate: string;

	public unsubscribed: boolean;

	public accessFailedCount: number;
	public lockoutEnabled: boolean;
	public lockoutEndDateTimeUtc: string;

	public archived: boolean;

	public entities: UserEntity[];


	constructor() {
		this.id = 0;
		this.guid = null;

		this.tfaEnabled = false;
		
		this.email = '';
		this.firstName = '';
		this.lastName = '';
		this.phone = '';

		this.accountType = UserAccountType.NotSpecified;
		this.administrator = false;
		this.position = null;

		this.picture = null;
		this.organisationId = null;

		this.primaryContact = false;
		this.billingContact = false;
		this.summaryEmailFrequency = UserSummaryEmailFrequency.Monthly;
		this.lastSummaryEmailDate = null;

		this.emailConfirmed = false;
		this.changePassword = false;
		this.changePasswordSentDate = null;

		this.unsubscribed = false;

		this.lockoutEnabled = false;
		this.lockoutEndDateTimeUtc = null;
		this.accessFailedCount = 0;

		this.archived = false;

		this.entities = [];
	}
}
