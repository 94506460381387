import { HttpErrorResponse } from '@angular/common/http';

export class ErrorHelper {


	public static processError(response: HttpErrorResponse | string): string {
		if (response === null) {
			return null;
		}
		if (typeof response === 'string') {
			return response;
		}

		console.error('ProcessError()', response);

		if (response.status === 404) {
			return 'The requested resource could not be found.';
		} else if (response.status === 0) {
			return 'Failed to get a reasonable response from the server.';
		} else if (response.status === 403 && response.error?.error === 'Forbidden') {
			return `Something has gone wrong. You do not have access to this resource. (403)`;
		} else if (response.error && !response.error.errors) {
			// API Error
			if (response.error.errorText) {
				return `${response.error.errorText} (${response.error.status})`;
			} else if (response.error.message) {
				return `${response.error.message}`;
			} else {
				return `${response.error}`;
			}
		} else if (response.error && response.error.errors) {
			// API System Error (e.g. ViewModel validation error)
			let error = '';

			for (const key in response.error.errors) {
				// TODO: Test
				if (Object.prototype.hasOwnProperty.call(response.error.errors, key)) {
					for (const err of response.error.errors[key]) {
						error = `${error} \r\n${err}`;
					}
				}
			}
			if (error !== '') {
				// (400) One or more validation errors occurred.
				// The JSON value could not be converted to Intello.Models.Entities.UserPositionType. Path: $.position | LineNumber: 0 | BytePositionInLine: 147.
				return `${response.error.title} (${response.error.status}) ${error}`;
			} else {
				return `${response.error.title} (${response.error.status})`; // (400) One or more validation errors occurred.
			}

		} else {
			console.error('ProcessError(). Processing response.statusText');
			return (
				response.statusText + ' ' + response.status + ' \r\n' + response.message
			);
		}
	}
}
