
import * as moment from 'moment';
const momentConstructor: (value?: any) => moment.Moment = (moment as any).default || moment;

export enum PastDateRangeCriteria {
	NotSpecified = 0,
	Last7Days,
	Last14Days,
	Last28Days,
	Last3Months,
	Last6Months,
	Custom,
}

export enum FutureDateRangeCriteria {
	NotSpecified = 0,
	Overdue,
	Next7Days,
	Next14Days,
	Next28Days,
	Next3Months,
	Next6Months,
	Custom,
}


export class DateRangeHelper {

	public static BuildPastDateString(value: PastDateRangeCriteria): string {
		switch (value) {
			case PastDateRangeCriteria.Last7Days:
				return momentConstructor().add(-7, 'days').format('YYYY-MM-DD');
			case PastDateRangeCriteria.Last14Days:
				return momentConstructor().add(-14, 'days').format('YYYY-MM-DD');
			case PastDateRangeCriteria.Last28Days:
				return momentConstructor().add(-28, 'days').format('YYYY-MM-DD');
			case PastDateRangeCriteria.Last3Months:
				return momentConstructor().add(-3, 'months').format('YYYY-MM-DD');
			case PastDateRangeCriteria.Last6Months:
				return momentConstructor().add(-6, 'months').format('YYYY-MM-DD');
			default: return null;
		}
	}

	public static BuildFutureDateString(value: FutureDateRangeCriteria): string {
		switch (value) {
			case FutureDateRangeCriteria.Overdue:
				return momentConstructor().format('YYYY-MM-DD');
			case FutureDateRangeCriteria.Next7Days:
				return momentConstructor().add(7, 'days').format('YYYY-MM-DD');
			case FutureDateRangeCriteria.Next14Days:
				return momentConstructor().add(14, 'days').format('YYYY-MM-DD');
			case FutureDateRangeCriteria.Next28Days:
				return momentConstructor().add(28, 'days').format('YYYY-MM-DD');
			case FutureDateRangeCriteria.Next3Months:
				return momentConstructor().add(3, 'months').format('YYYY-MM-DD');
			case FutureDateRangeCriteria.Next6Months:
				return momentConstructor().add(6, 'months').format('YYYY-MM-DD');
			default: return null;
		}
	}
}
