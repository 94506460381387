import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import * as FileSaver from 'file-saver';

import { ChildComponentBase } from '@global/bases/child-component-base';

import { ActivityLog, ActivityLogAction } from '@global/models/activity-logs/activity-log';
import { ActivityLogDocument } from '@global/models/activity-logs/activity-log-document';
import { Entity } from 'projects/advisor/src/app/core/models/entities/entity';
import { ClientQuery } from '@global/models/client-queries/client-query';
import { ClientQueryDocumentService } from '@global/services/client-query-document.service';
import { ExpressDocumentService } from 'projects/advisor/src/app/core/services/express-document.service';

import { Job } from '../../../../../advisor/src/app/core/models/jobs/job';
import { JobCategory } from 'projects/advisor/src/app/core/models/job-categories/job-category';
import { JobStatusService } from 'projects/advisor/src/app/core/services/job-status.service';
import { JobStatus } from 'projects/advisor/src/app/core/models/job-statuses/job-status';

@Component({
	selector: 'app-activity-feed',
	templateUrl: './activity-feed.component.html',
	styleUrls: ['./activity-feed.component.css']
})
export class ActivityFeedComponent extends ChildComponentBase implements OnInit {
	readonly ActivityLogAction = ActivityLogAction;

	public jobStatuses: JobStatus[] = null;
	
	@Input() public showEmailNotifications: boolean = false;
	@Input() public trusteeView: boolean = false;

	@Input() public entity: Entity;
	@Input() public job: Job;
	@Input() public clientQuery: ClientQuery;
	@Input() public items: ActivityLog[];

	constructor(
		public _router: Router,
		public _clientQueryDocumentService: ClientQueryDocumentService,
		public _expressDocumentService: ExpressDocumentService,
		public _jobStatusService: JobStatusService) {
		super();

		this.entity = null;
		this.items = null;
	}

	ngOnInit(): void {
		this._jobStatusService.getActiveList().subscribe(
			(data: JobStatus[]) => {
				var jobStatuses = [];
				this.jobStatuses = data;
			},
			(error: HttpErrorResponse) => {
				this.busy = false;
				this.raiseError(error);
			}
		);
	}

	onDownloadClick(activityLog: ActivityLog, document: ActivityLogDocument): void {
		this.busy = true;
		if (activityLog.clientQuery) {
			// Client Query Document...
			this._clientQueryDocumentService.downloadTempFile(activityLog.clientQuery.id, document.id).subscribe(
				blob => {
					FileSaver.saveAs(blob, document.name);
					this.busy = false;
				},
				(error: HttpErrorResponse) => {
					this.busy = false;
					this.raiseError(error);
				}
			);
		} else {
			// Express Document...
			this._expressDocumentService.downloadTempFile(activityLog.entity.id, document.id).subscribe(
				blob => {
					FileSaver.saveAs(blob, document.name);
					this.busy = false;
				},
				(error: HttpErrorResponse) => {
					this.busy = false;
					this.raiseError(error);
				}
			);
		}
	}
}
