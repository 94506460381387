import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

	constructor(
		private _zone: NgZone,
		private _router: Router,
		private _activatedRoute: ActivatedRoute) {
			
	}

	ngOnInit() {

	}
}
