import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';


@Directive({
	selector: '[appDropZoneContainer]'
})
export class DropZoneContainerDirective {
	
	@HostBinding('class.dragging') dragging: boolean;

	@Output() filesDropped = new EventEmitter<any>();

	// Drag-Enter
	@HostListener('dragenter', ['$event']) onDragEnter(event) {
		console.log('dragenter');
		event.preventDefault();
		event.stopPropagation();
		if (!this.dragging) {
			this.dragging = true;
		}
	}

	// Drag-Over
	@HostListener('dragover', ['$event']) onDragOver(event) {
		console.log('dragover');
		event.preventDefault();
		event.stopPropagation();
		if (!this.dragging) {
			this.dragging = true;
		}
	}

	// Drag-Leave
	@HostListener('dragleave', ['$event']) public onDragLeave(event) {
		console.log('dragleave');
		event.preventDefault();
		event.stopPropagation();
		
		if (this.dragging) {
			this.dragging = false;
		}
	}

	// Drop
	@HostListener('drop', ['$event']) public ondrop(event) {
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
		const files = event.dataTransfer.files;
		if (files.length > 0) {
			this.filesDropped.emit(files);
		}
	}
}
