import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ErrorHelper } from '@global/helpers/error-helper';

import { AuthService } from '@global/auth/auth.service';
import { BrandingService } from 'projects/advisor/src/app/core/services/branding.service';

import { Branding } from '@global/models/branding/branding';
import { LoginResponse, LoginResultType } from '@global/models/accounts/login-response';

import { SmsLoginRequest } from '../../core/models/sms-login-request';
import { SmsAccountService } from '../../core/services/sms-account.service';
import { SendSmsRequest } from '../../core/models/send-sms-request';
import { SendSmsResponse, SendSmsResultType } from '../../core/models/send-sms-response';
import { NgForm } from '@angular/forms';

@Component({
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
	public busy: boolean = false;
	public error: HttpErrorResponse | string = null;
	public paramsError: string = null;

	private subscriptions: Subscription = new Subscription();

	private verificationGuid: string = null;

	public loginResult: LoginResultType = LoginResultType.NotSpecified;
	public expirationDateUtc: string;

	public branding: Branding;

	public item: SmsLoginRequest;
	public phoneNumberMask: string = null;

	@ViewChild('mainForm') mainForm: NgForm;

	constructor(
		public _service: SmsAccountService,
		public _auth: AuthService,
		public _brandingService: BrandingService,
		public _activatedRoute: ActivatedRoute,
		private _router: Router) {
	}

	ngOnInit() {
		this.readParams();
		this.loadBranding();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	readParams(): void {
		this._activatedRoute.queryParams.subscribe(params => {
			this.paramsError = null;
			if (params['auth']) {
				this.verificationGuid = params['auth'];
				this.sendSmsCode();
			} else {
				this.paramsError = 'The authentication code is invalid or missing. Unable to process the request.';
			}
		});
	}

	buildRedirectUrl(clientQueryGuid: void): void {
		console.log(`buildRedirectUrl: ${clientQueryGuid}`);
	}

	loadBranding(): void {
		this.busy = true;
		this.error = null;

		const subscription = this._brandingService.get().subscribe(
			(data: Branding) => {
				this.busy = false;
				this.branding = data;
			}, (response: HttpErrorResponse) => {
				this.error = ErrorHelper.processError(response);
			}
		);

		this.subscriptions.add(subscription);
	}

	sendSmsCode(): void {
		this.busy = true;

		const item = new SendSmsRequest();
		item.verificationGuid = this.verificationGuid;

		this._service.sendSmsCode(item).subscribe(
			(data: SendSmsResponse) => {
				this.busy = false;
				this.phoneNumberMask = data.phoneNumberMask;

				if (data.result === SendSmsResultType.Success) {
					this.item = new SmsLoginRequest();
					this.item.verificationGuid = this.verificationGuid;
				} else if (data.result === SendSmsResultType.AlreadySent) {
					this.error = data.message;
					this.item = new SmsLoginRequest();
					this.item.verificationGuid = this.verificationGuid;
				} else {
					this.error = data.message;
				}
			}, (response: HttpErrorResponse) => {
				this.error = ErrorHelper.processError(response);
			}
		);
	}

	onSubmit(): void {
		console.log('Validation success...', this.mainForm);

		this.busy = true;
		this.error = null;
		this.loginResult = null;
		this._service.loginWithSmsCode(this.item).subscribe(
			(response: LoginResponse) => {
				this.busy = false;
				this.loginResult = null;

				switch (response.result) {
					case LoginResultType.Success:
						this._auth.setAuthorisation(response);
						console.log(`RedirectUrl: ${this._auth.redirectUrl}`);
						this._router.navigateByUrl(this._auth.redirectUrl ?? '');
						this._auth.redirectUrl = null;
						break;
					case LoginResultType.InvalidTfaCode:
						this.loginResult = response.result;
						break;
					case LoginResultType.InvalidUserNameOrPassword:
						this.loginResult = response.result;
						break;
					default:
						this.loginResult = response.result;
						this.expirationDateUtc = response.expirationDateUtc;
						break;
				}
			},
			(error: HttpErrorResponse) => {
				this.busy = false;
				this.error = error;
			}
		);
	}
}
