
import * as moment from 'moment';
const momentConstructor: (value?: any) => moment.Moment = (moment as any).default || moment; // under systemjs, moment is actually exported as the default export, so we account for that

export class MomentHelper {

	static endOfFinancialYear(value: moment.Moment): moment.Moment {
		const nextYear = value.year() + 1;
		const momentDate = moment().utc(true).year(nextYear).month(6).date(30).startOf('day');
		return momentDate;
	}

	static nextMonthUtc(): string {
		const nextMonth = moment().utc(true).clone().startOf('month').startOf('day').add(1, 'month');
		return nextMonth.toISOString();
	}

	static toShortDate(value: moment.Moment): string {
		if (!value || value === null) {
			return '';
		}

		if (value.year() === 1900) {
			return '';
		}

		return momentConstructor(value).format('DD MMM YYYY');
	}

	static toShortMonth(value: moment.Moment): string {
		if (value == null) {
			return '';
		}

		if (value.year() === 1900) {
			return '';
		}

		return momentConstructor(value).format('MMM YYYY');
	}

	static toShortDateTime(value: moment.Moment): string {
		if (!value) {
			return '';
		}

		if (value.year() === 1900) {
			return '';
		}

		return momentConstructor(value).format('DD MMM YYYY HH:mm');
	}
	
	static toShortTime(value: moment.Moment): string {
		if (!value) {
			return '';
		}

		if (value.year() === 1900) {
			return '';
		}

		return momentConstructor(value).format('HH:mm');
	}

	static stringToShortDate(value: string): string {
		if (!value) {
			return null;
		}
		const date = momentConstructor(value);

		if (date.year() === 1900) {
			return null;
		}

		return MomentHelper.toShortDate(date);
	}

	static stringToShortMonth(value: string): string {
		if (!value) {
			return '';
		}
		const date = momentConstructor(value);

		if (date.year() === 1900) {
			return null;
		}

		return MomentHelper.toShortMonth(date);
	}
	
	static stringToShortTime(value: string): string {
		if (!value) {
			return '';
		}
		const date = momentConstructor(value);

		if (date.year() === 1900) {
			return '';
		}

		return MomentHelper.toShortTime(date);
	}

	static stringToShortDateTime(value: string): string {
		if (!value) {
			return '';
		}
		const date = momentConstructor(value);

		if (date.year() === 1900) {
			return '';
		}

		return MomentHelper.toShortDateTime(date);
	}
}
