<!-- Modal -->
<app-bootstrap-modal [size]="size" centered="true" #modal>
	<div class="model-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ title }}</h4>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col">
					<p style="white-space: pre-line;">{{ message }}</p>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-sm btn-wide btn-outline-primary" (click)="yesClicked()">Yes</button>
			<button type="button" class="btn btn-sm btn-wide btn-outline-primary" (click)="noClicked()">No</button>
		</div>
	</div>
</app-bootstrap-modal>