// Angular
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// 3rd Party

// Components
import { AppComponent } from './app.component';
import { ApiUrlInterceptor } from '@global/auth/api-url.interceptor';

// Providers
import { Auth401Interceptor } from '@global/auth/auth-401.interceptor';
import { AuthGuardService as AuthGuard } from '@global/auth/auth-guard.service';
import { AuthJwtInterceptor } from '@global/auth/auth-jwt.interceptor';

// Application Modules
import { GlobalModule } from 'projects/global/src/lib/global.module';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ComponentsModule } from './components/components.module';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		// Angular
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,

		// 3rd Party
		GlobalModule,
		
		// Application Modules...
		AppRoutingModule,
		CoreModule,
		ComponentsModule,
	],
	exports: [
		RouterModule,
	],
	providers: [
		AuthGuard,
		{
			provide: LOCALE_ID,
			useValue: 'en-AU'
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthJwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiUrlInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Auth401Interceptor,
			multi: true
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
