
<div class="modal fade" tabindex="-1" role="dialog" 
	style="z-index: 1040 !important;"
	[style.z-index]="zindex"
	aria-hidden="true"
	[attr.backdropStatic]="'static' ? '' : backdropStatic"
	[ngClass]="{'show': _visibleAnimate}"
	[ngStyle]="{'display': _visible ? 'block' : 'none', 'opacity': _visibleAnimate ? 1 : 0}"
	(click)="onContainerClicked($event)">

	<div class="modal-dialog" role="document" 
		[ngClass]="{'modal-dialog-scrollable': scrollable, 'modal-dialog-centered': centered, 'modal-sm': size == 'small', 'modal-xl': size == 'x-large', 'modal-lg': size == 'large'}"
		>
		<div class="modal-content">
			<ng-content></ng-content>
		</div>
	</div>
</div>