import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { Branding } from '@global/models/branding/branding';
import { AuthService } from '@global/auth/auth.service';
import { ClientBrandingService } from '@client/core/services/client-branding.service';
import { ActivatedRoute } from '@angular/router';

import { AppSettingsFactory } from '@global/services/app-settings-factory';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
	public branding: Branding;
	
	private subscriptions: Subscription = new Subscription();

	constructor(
		public _auth: AuthService,
		public _clientBrandingService: ClientBrandingService,
		private _activatedRoute: ActivatedRoute,
		public _settings: AppSettingsFactory) {
		// Nothing to do here.
	}

	ngOnInit(): void {

		this._activatedRoute.queryParams.subscribe(params => {
			if (params['org']) {
				const organisationCode = params['org'];
				
				const subscription = this._clientBrandingService.get(organisationCode).subscribe(
					(data: Branding) => {
						this.branding = data;
					},
					(error: HttpErrorResponse) => {
						console.log('Error loading Branding: ', error); // Lets just swallow this error
					}
				);
				this.subscriptions.add(subscription);
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
