import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BootstrapModalComponent } from '@global/components/bootstrap-modal/bootstrap-modal.component';

/*
  Provides a base class for all the modal forms.

  Features:
  * busy (in)
  * error / processError()
  * validate()
*/

@Component({
	selector: 'app-modal-base',
	template: '<div></div>'
})
// tslint:disable-next-line:component-class-suffix
export abstract class ModalComponentBase {
	@Input() public busy: boolean = false;

	public error: HttpErrorResponse | string = null;
	@Output() errorChange: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

	@ViewChild('modal', { static: false }) modal: BootstrapModalComponent;

	onError(error: HttpErrorResponse) {
		// console.log('onError()', error);
		this.error = error;
	}

	onCloseClick(): void {
		// console.log('onCloseClick()');
		this.close();
	}

	processError(error: HttpErrorResponse): void {
		this.busy = false;
		this.error = error;
	}

	// This is used when loading the item before the modal is displayed so the parent can show the error message.
	raiseError(error: HttpErrorResponse): void {
		this.busy = false;
		this.errorChange.emit(error);
	}

	clearError(): void {
		this.error = null;
	}
	
	close(): void {
		// console.log('close()');
		this.busy = false;
		this.modal.hide();
	}
}
