import { Injectable } from '@angular/core';

/*
	Provides random colors that are consistent while the application is running.

	https://angular.io/guide/singleton-services
*/
@Injectable({ providedIn: 'root' })
export class ColorService {

	constructor() {
		this.randomColors = ColorService.shuffle(ColorService.BASE_COLORS);
	}
	static readonly BASE_COLORS = ['#ef5350', '#ec407a', '#7e57c2', '#5c6bc0', '#42a5f5', '#29b6f6', '#26c6da', '#26a69a', '#66bb6a', '#9ccc65', '#d4e157', '#ffee58', '#ffca28', '#ffa726', '#ff7043'];

	public randomColors: string[];


	public static shuffle(array: string[]): string[] {
		let currentIndex: number = array.length;
		let temporaryValue: any;
		let randomIndex: number;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {

		  // Pick a remaining element...
		  randomIndex = Math.floor(Math.random() * currentIndex);
		  currentIndex -= 1;

		  // And swap it with the current element.
		  temporaryValue = array[currentIndex];
		  array[currentIndex] = array[randomIndex];
		  array[randomIndex] = temporaryValue;
		}

		return array;
	}

	// Returns a random color based on the seed value provided.
	getRandomColor(seed: string): string {
		let index = 0;

		if (seed) {
			for (let i = 0; i < seed.length; i++) {
				index = index + seed.charCodeAt(i);
			}
			index = index % this.randomColors.length;
		}
		
		return this.randomColors[index];
	}
}
