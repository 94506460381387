
export class Branding {
	public primaryColor: string;
	public secondaryColor: string;

	public icon: string;
	public whiteLogo: string;
	public normalLogo: string;

	public subdomain: string;


	public static getDefaultBrand(): Branding {
		const item = new Branding();
		item.primaryColor = 'black'; // '#3F4254';
		item.secondaryColor = '#ffffff';
		item.icon = 'favicon.png';
		item.whiteLogo = 'assets/images/intello_white.png';
		item.normalLogo = 'assets/images/intello.png';
		item.subdomain = null;
		return item;
	}
}
