<div style="padding-top: 120px; padding-left: 10px; padding-right: 10px;">
	<div style="float: none; margin-left: auto; margin-right: auto; max-width: 600px;">

		<!-- Error Message -->
		<ng-container *ngIf="!item && error">
			<span class="text-danger">{{ error }}</span>
		</ng-container>

		<!-- Error Message -->
		<ng-container *ngIf="paramsError">
			<div class="alert alert-warning text-center">{{ paramsError }}</div>
		</ng-container>
		
		<div *ngIf="item && branding">
			<form #mainForm="ngForm" (ngSubmit)="onSubmit()">
				<div class="card shadow-lg">
					<div class="card-body">
						<div class="text-center mt-5 mb-5">
							<img [src]="branding.normalLogo" style="max-width: 340px; max-height: 200px;" />
						</div>
						<div class="text-center h3 pb-4 pt-4">
							An SMS has been sent to your phone<span *ngIf="phoneNumberMask"> ({{ phoneNumberMask }})</span><br/>
						</div>

						<div class="row">
							<div class="col-3">

							</div>
							<div class="col-6">
								<label class="form-check-label mb-2" for="flexCheckDefault">
									Please enter the code below:
								</label>

								<input type="text" class="form-control mb-3" name="smsCode" #smsCode="ngModel" autocomplete="off"
									[(ngModel)]="item.smsCode" placeholder="SMS authentication code"
									title="Please enter the SMS code" autocomplete="nope" required>
							</div>
						</div>

						<!-- Error Message -->
						<ng-container *ngIf="error">
							<div class="alert alert-warning text-center">{{ error }}</div>
						</ng-container>

						<!-- Login Result-->
						<div class="row pt-2" *ngIf="loginResult">
							<div class="col">
								<div class="alert alert-warning">{{ loginResult | loginResultName: expirationDateUtc }}</div>
							</div>
						</div>
						
						<div class="row pt-2">
							<div class="col">
								<div class="d-grid">
									<button type="submit" [style.background-color]="branding.primaryColor"
										class="btn btn-dark" [disabled]="busy">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	</div>
</div>