import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { CacheService } from '../../../../../global/src/app/services/cache.service';

import { Branding } from '../../../../../global/src/app/models/branding/branding';

//
// This is used to load the branding.
// NOTE: There is also an OrganisationBranding service for creating the brands.
//
@Injectable({ providedIn: 'root' })
export class ClientBrandingService implements OnDestroy {
	private baseUrl = '/Branding';

	// Cache
	public invalidCache: boolean = true;
	public organisationCode: string = null;
	private subscriptions: Subscription = new Subscription();
	private brandingSubject = new ReplaySubject<Branding>(1);


	constructor(
		private _httpClient: HttpClient,
		private _cacheService: CacheService) {

		const subscription = _cacheService.listen().subscribe(
			(change) => {
				if (!this.invalidCache) {
					console.log('%cBrandingService: Clearing cache.', 'color: cornflowerblue;');
					this.invalidCache = true;
				}
			},
		);
		this.subscriptions.add(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get(code: string): Observable<Branding> {
		console.log('%c[[BrandingService]]: Loading Branding...', 'color: blue;');
		if (this.organisationCode != code) {
			this.invalidCache = true;
		}
		if (!this.brandingSubject.observers.length || this.invalidCache) {
			const url = `${this.baseUrl}/getByCode?code=${code}`;
			this._httpClient.get<Branding>(url).subscribe(
				(data: Branding) => {
					if (data == null) {
						data = Branding.getDefaultBrand();
						console.log('%c[[BrandingService]]: Using default Branding...', 'color: blue;');
					} else {
						console.log(`[[BrandingService]]: `, data);
					}
					this.brandingSubject.next(data);
					this.invalidCache = false;
				},
				error => {
					this.brandingSubject.error(error);
					this.brandingSubject = new ReplaySubject(1);
				}
			);
		}

		return this.brandingSubject;
	}
}
