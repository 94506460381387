import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';

import { AuthService } from '@global/auth/auth.service';


import { ArrowPathItem } from '@global/components/arrow-path/arrow-path-item';
import { ClientQuery, ClientQueryStatusType } from '@global/models/client-queries/client-query';
import { ClientQueryStatusNamePipe } from '@global/pipes/client-query-status-name.pipe';

@Component({
	selector: 'app-home-header',
	templateUrl: './home-header.component.html'
})
export class HomeHeaderComponent {
	readonly ClientQueryStatusType = ClientQueryStatusType;
	
	public statuses: ArrowPathItem[];

	private _clientQuery: ClientQuery;
	@Input() public set clientQuery(value: ClientQuery) {
		this._clientQuery = value;
		this.populateStatuses(this._clientQuery);
	}
	public get clientQuery(): ClientQuery {
		return this._clientQuery;
	}

	constructor(
		public _auth: AuthService,
		public _location: Location) {

		this.clientQuery = null;
	}

	populateStatuses(clientQuery: ClientQuery): void {
		const statuses: ArrowPathItem[] = [];

		if (clientQuery == null) {
			return;
		}

		for (const statusType of ClientQueryStatusNamePipe.StatusTypeText) {
			if (statusType.value == ClientQueryStatusType.NotSpecified) {
				continue;
			}
			if (!this._auth.isStaff() && statusType.value == ClientQueryStatusType.Draft) {
				continue;
			}
			if (statusType.value == ClientQueryStatusType.Completed && clientQuery.status === ClientQueryStatusType.Cancelled)
			{
				continue;
			}
			if (statusType.value == ClientQueryStatusType.Cancelled && clientQuery.status !== ClientQueryStatusType.Cancelled)
			{
				continue;
			}

			const item = new ArrowPathItem();
			item.id = statusType.value;
			item.text = statusType.name;

			if (clientQuery.status === ClientQueryStatusType.Cancelled)
			{
				item.color = 'red';
			}
			statuses.push(item);
		}

		this.statuses = statuses;
	}
}
