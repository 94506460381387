import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Paginator, SortDirection } from '@global/models/paginator';
import { SelectItem } from '../models/select-item';
import { JobStatus } from '../models/job-statuses/job-status';
import { JobStatusSearchLine } from '../models/job-statuses/job-status-search-line';
import { CacheService } from '@global/services/cache.service';

export enum JobStatusSearchOrderBy {
	NotSpecified = 0,
	Name,
	Order
}

export enum JobStatusSearchType {
	NotSpecified = 0,
	Active,
	Archived
}

@Injectable({ providedIn: 'root' })
export class JobStatusService implements OnDestroy {
	private baseUrl = `/JobStatuses`;

	// Cache
	public invalidCache: boolean = false;
	private subscriptions: Subscription = new Subscription();
	private selectListSubject = new ReplaySubject<SelectItem[]>(1);


	constructor(
		private _httpClient: HttpClient,
		private _cacheService: CacheService
		) {

		const subscription1 = _cacheService.listen().subscribe(
			(change) => {
				if (!this.invalidCache) {
					console.log('%cJobStatusService: Clearing cache.', 'color: cornflowerblue;');
					this.invalidCache = true;
				}
			},
		);
		this.subscriptions.add(subscription1);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}


	get(id: number): Observable<JobStatus> {
		const url = `${this.baseUrl}/${id}`;

		return this._httpClient.get<JobStatus>(url).pipe(
			tap((result: JobStatus) => {
				console.log(`get(${id})`, result);
			}));
	}

	save(item: JobStatus): Observable<JobStatus> {
		if (item.id === 0) {
			return this.create(item);
		} else {
			return this.update(item);
		}
	}

	create(item: JobStatus): Observable<JobStatus> {
		const url = `${this.baseUrl}`;
		console.log('create()', item);

		return this._httpClient.post(url, item).pipe(
			tap((result: JobStatus) => {
				console.log('create()', result);
			}));
	}

	update(item: JobStatus): Observable<JobStatus> {
		const url = `${this.baseUrl}/${item.id}`;

		return this._httpClient.put(url, item).pipe(
			tap((result: JobStatus) => {
				console.log('update()', result);
			}));
	}
	
	getList(): Observable<JobStatus[]> {
		const url = `${this.baseUrl}/getList`;
		return this._httpClient.get<JobStatus[]>(url);
	}

	getActiveList(): Observable<JobStatus[]> {
		const url = `${this.baseUrl}/getActiveList`;
		return this._httpClient.get<JobStatus[]>(url);
	}

	getSelectList(): Observable<SelectItem[]> {
		const url = `${this.baseUrl}/getSelectList`;

		if (!this.selectListSubject.observers.length || this.invalidCache) {
			this._httpClient.get<SelectItem[]>(url).subscribe(
				(data: SelectItem[]) => {
					console.log(`BrandingService: `, data);
					this.selectListSubject.next(data);
					this.invalidCache = false;
				},
				error => {
					this.selectListSubject.error(error);
					this.selectListSubject = new ReplaySubject(1);
				}
				);
		}

		return this.selectListSubject;
	}

	search(currentPage: number, itemsPerPage: number, searchType: JobStatusSearchType, orderBy: JobStatusSearchOrderBy, sortDirection: SortDirection)
		: Observable<Paginator<JobStatusSearchLine>> {
		const url = `${this.baseUrl}/Search?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}&searchType=${searchType}&orderBy=${orderBy}&sortDirection=${sortDirection}`;

		return this._httpClient.get<Paginator<JobStatusSearchLine>>(url);
	}
}
