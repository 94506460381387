import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

/*
	This is used to tell the application services to reset their cache.
*/
@Injectable({ providedIn: 'root' })
export class CacheService {
	private resetSubject = new ReplaySubject<void>(1);

	/**
	 * Subscribed to by the services so they know when to reset their cache.
	 */
	public listen(): Observable<void> {
		return this.resetSubject;
	}
	
	/**
	 * If called it will send the reset signal to all services.
	 */
	public clear(): void {
		console.log('%c[[CacheService]]: Reset called...', 'color: cornflowerblue;');
		this.resetSubject.next();
	}
}
