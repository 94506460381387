import { Component, OnInit } from '@angular/core';
import { AuthService } from '@global/auth/auth.service';


@Component({
	templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

	constructor(public _auth: AuthService) {

	}

	ngOnInit() {
		this._auth.logout();
	}
}
