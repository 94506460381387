import { Injectable } from '@angular/core';
import { IAppSetttings } from '@global/models/app-settings';
import { environment } from '../../../environments/environment';

/*
  This is duplicated by each project as each project needs to reference a different version of the environment.
*/
@Injectable({ providedIn: 'root' })
export class AppSettingsService implements IAppSetttings {

	get apiUrl(): string {
		return environment.apiUrl;
	}
	get broadcast(): boolean {
		return environment.broadcast;
	}
	get production(): boolean {
		return environment.production;
	}
	get integraPayProduction(): boolean {
		return environment.integraPayProduction;
	}
	get version(): string {
		return environment.version;
	}
}
